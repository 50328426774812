import { DeleteOutline } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { MobileCardTitle, MobileItemCard } from "commons/styled.components";
import CartQuantityButton from "components/cartbutton";
import keys from "locale/keys";
import { useTranslation } from "react-i18next";

import { CartItemProps } from "./interface";
import {
  CartItemWrapper,
  ItemContainer,
  PointsText,
  RemoveButton,
  RemoveButtonContainer,
  StockInfoWrapper,
  StockText,
  TitleStack,
  TitleText,
  ValueText,
} from "./styled.components";

const CartItem = (props: CartItemProps) => {
  const { t } = useTranslation();
  const {
    id,
    title,
    value,
    coins,
    instock = true,
    quantity,
    deleteCartItemHandler,
    updateItemQuantityHandler,
    isMobile = false,
  } = props;

  const onQuantityChangeHandler = (
    latestQuantity: number,
    isIncrement: boolean,
  ) => {
    updateItemQuantityHandler(id, latestQuantity);
  };

  if (isMobile) {
    return (
      <MobileItemCard>
        <Stack direction="row" justifyContent="space-between">
          <MobileCardTitle>{title}</MobileCardTitle>
          <div>
            <StockInfoWrapper instock={instock}>
              <StockText instock={instock}>
                {instock ? t(keys.in_stock) : t(keys.no_stock)}
              </StockText>
            </StockInfoWrapper>
          </div>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ValueText>{value}</ValueText>
          <CartQuantityButton
            disabled={!instock}
            initialCount={quantity}
            onChangeHandler={onQuantityChangeHandler}
          />
          <ValueText>{coins}</ValueText>
        </Stack>
        <RemoveButtonContainer>
          <IconButton
            disabled={!instock}
            size="medium"
            onClick={() => deleteCartItemHandler?.(id)}
          >
            <DeleteOutline />
          </IconButton>
        </RemoveButtonContainer>
      </MobileItemCard>
    );
  }

  return (
    <CartItemWrapper instock={instock}>
      <TitleStack>
        <TitleText>{title}</TitleText>
        <ValueText>{value}</ValueText>
      </TitleStack>
      <ItemContainer>
        <StockInfoWrapper instock={instock}>
          <StockText instock={instock}>
            {instock ? t(keys.in_stock) : t(keys.no_stock)}
          </StockText>
        </StockInfoWrapper>
      </ItemContainer>
      <ItemContainer>
        <CartQuantityButton
          disabled={!instock}
          initialCount={quantity}
          onChangeHandler={onQuantityChangeHandler}
        />
      </ItemContainer>
      <PointsText>{coins}</PointsText>
      <RemoveButton
        disabled={!instock}
        size="small"
        startIcon={<DeleteOutline />}
        onClick={() => deleteCartItemHandler?.(id)}
      >
        {t(keys.remove)}
      </RemoveButton>
    </CartItemWrapper>
  );
};

export default CartItem;
