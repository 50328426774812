import { GenericTableProps, TableItemProps } from "commons/interface";
import { TicketDetailsProps } from "data/slices/support/interface";
import { formatDate } from "date-fns";
import { isEqual } from "lodash";
import {
  EARNINGS_DATE_FORMAT,
  TABLE_TYPE_SUPPORT_LIST,
  TICKET_TYPE,
} from "utils/constants";

export const getSupportTickets = (
  tickets: TicketDetailsProps[],
): GenericTableProps => {
  const supportTickets: TableItemProps[] = [];

  if (tickets) {
    tickets.forEach((ticket) => {
      supportTickets.push({
        id: ticket.id,
        category: ticket.category,
        completedDate: formatDate(ticket.createdAt, EARNINGS_DATE_FORMAT),
        status: isEqual(ticket.status, "Open")
          ? TICKET_TYPE.OPEN
          : TICKET_TYPE.RESOLVED,
        description: ticket.description,
      });
    });
  }

  return {
    tableType: TABLE_TYPE_SUPPORT_LIST,
    headerItems: getHeaders(),
    tableItems: supportTickets,
  };
};

const getHeaders = () => {
  return ["Ticket Number", "Category", "Date", "Status", "Action"];
};
