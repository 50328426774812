import styled from "@emotion/styled";
import { COLORS } from "utils/colors";

export const BannerWrapper = styled.div`
  display: flex;
  height: 80px;
  padding: 0px 16px 0px 32px;
  align-items: center;
  border-radius: 8px;
  margin: 8px 32px;
  border-bottom: 1px solid #e4e4e4;
  background: ${COLORS.BANNER_BG};
  box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.04);
`;
