import { GenericTableProps, TableItemProps } from "commons/interface";
import { InvitationListRowResponse } from "data/slices/referals/interface";
import { formatDate } from "date-fns";
import i18n from "i18n";
import keys from "locale/keys";
import { SURVEY_DATE_FORMAT, TABLE_TYPE_REFERALS } from "utils/constants";

export const getReferalsList = (
  rows: InvitationListRowResponse[],
): GenericTableProps => {
  const referalsList: TableItemProps[] = [];

  if (rows) {
    rows.forEach((item) => {
      referalsList.push({
        title: item.firstName,
        status: item.status,
        email: item.email,
        points: "-",
        completedDate: formatDate(item.createdAt, SURVEY_DATE_FORMAT),
      });
    });
  }

  return {
    tableType: TABLE_TYPE_REFERALS,
    headerItems: getHeaders(),
    tableItems: referalsList,
  };
};

const getHeaders = () => {
  return [
    i18n.t(keys.name),
    i18n.t(keys.status),
    i18n.t(keys.email),
    i18n.t(keys.sent_date),
  ];
};
