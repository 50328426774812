import LoadingEmptyError from "components/LoaderEmptyErrorState";

import { NotificationProps } from "./interface";
import NotificationItem from "./NotificationItem";

const Notifications = (props: NotificationProps) => {
  const { notificationsToDisplay } = props;

  if (notificationsToDisplay?.length === 0) {
    return (
      <LoadingEmptyError isLoading={false} isEmpty={true} isError={false} />
    );
  }

  return (
    <>
      {notificationsToDisplay.map((item, index) => {
        return (
          <NotificationItem
            date={item.date}
            title={item.title}
            description={item.description}
            key={index}
          />
        );
      })}
    </>
  );
};

export default Notifications;
