import styled from "@emotion/styled";

export const IconTextWrapper = styled.div`
  position: relative;
`;

export const CountText = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  border-radius: 9px;
  color: white;
  width: 20px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  padding-top: 2px;
  margin: 0;
`;
