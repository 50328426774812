import { TableItemProps } from "commons/interface";
import { CapsuleText, CapsuleWrapper } from "commons/styled.components";
import Button from "components/button";
import { BUTTON_SIZE } from "components/button/interface";
import CustomLink from "components/link";
import keys from "locale/keys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TABLE_TYPE_ORDERS,
  TABLE_TYPE_REFERALS,
  TABLE_TYPE_RETAILERS,
  TABLE_TYPE_SUPPORT_LIST,
  TABLE_TYPE_WALLET_EARNINGS,
} from "utils/constants";
import {
  getCouponStatusType,
  getStringWithDefaultValue,
  termsTextToShow,
} from "utils/Utils";

import calendar from "../../assets/icons/calendar.svg";
import RetailerPlaceholder from "../../assets/icons/CouponPlaceholder.svg";
import emailIcon from "../../assets/icons/Email.svg";
import {
  ItemWrapper,
  PointsItem,
  RetailerImageWrapper,
  StackItem,
  TableItemText,
  TableItemWrapper,
} from "./styled.components";

export const getStatus = (status: string) => {
  const type = getCouponStatusType(status);
  if (type === undefined) {
    return <></>;
  }

  return (
    <CapsuleWrapper type={type}>
      <CapsuleText type={type}>{status}</CapsuleText>
    </CapsuleWrapper>
  );
};

export const getPointsItem = (points: string) => {
  return (
    <ItemWrapper>
      <PointsItem>{points}</PointsItem>
    </ItemWrapper>
  );
};

const TableItem = (props: TableItemProps) => {
  const {
    tableType,
    transactionid,
    status,
    couponoff,
    value,
    points,
    dateofpurchase,
    surveyName,
    surveyNumber,
    category,
    completedDate,
    description,
    iconurl,
    id,
    title,
    email,
    onActionButtonClicked,
  } = props;
  const { t } = useTranslation();
  const [showFullText, setShowFullText] = useState(false);

  const getItemWithIcon = (icon: string, date: string) => {
    return (
      <StackItem direction="row" alignItems="center" gap={1}>
        <img src={icon} alt="calendar" width="16px" height="16px" />
        <TableItemText>{date}</TableItemText>
      </StackItem>
    );
  };

  const getOrdersItems = () => {
    return (
      <TableItemWrapper>
        <TableItemText>{transactionid}</TableItemText>
        <TableItemText>{couponoff}</TableItemText>
        <TableItemText>{value}</TableItemText>
        <TableItemText>{points}</TableItemText>
        {getItemWithIcon(calendar, dateofpurchase)}
        <ItemWrapper>{getStatus(status)}</ItemWrapper>
      </TableItemWrapper>
    );
  };

  const getWalletEarningsItem = () => {
    return (
      <TableItemWrapper>
        <TableItemText isbold={true}>{surveyName}</TableItemText>
        <TableItemText>{surveyNumber}</TableItemText>
        {getItemWithIcon(calendar, completedDate)}
        {getPointsItem(points)}
      </TableItemWrapper>
    );
  };

  const getSupportListItem = () => {
    return (
      <TableItemWrapper>
        <TableItemText isbold={true}>{id}</TableItemText>
        <TableItemText>{category}</TableItemText>
        {getItemWithIcon(calendar, completedDate)}
        <ItemWrapper>{getStatus(status)}</ItemWrapper>
        <ItemWrapper>
          <Button
            variant="outlined"
            size={BUTTON_SIZE.SMALL}
            onClickHandler={() =>
              onActionButtonClicked?.({
                id,
                category,
                status,
                description,
              })
            }
          >
            {t(keys.view)}
          </Button>
        </ItemWrapper>
      </TableItemWrapper>
    );
  };

  const getRetailsItem = () => {
    return (
      <TableItemWrapper>
        <RetailerImageWrapper>
          <img
            src={iconurl}
            alt="retailer not found"
            height="150px"
            width="250px"
            loading="lazy"
            onError={(e) => {
              e.currentTarget.src = RetailerPlaceholder;
            }}
          />
        </RetailerImageWrapper>
        <span>
          <TableItemText>
            {getStringWithDefaultValue(
              termsTextToShow(description, showFullText),
            )}
          </TableItemText>
          <CustomLink onClickHandler={() => setShowFullText((val) => !val)}>
            {showFullText ? t(keys.show_less) : t(keys.show_full)}
          </CustomLink>
        </span>
      </TableItemWrapper>
    );
  };

  const getReferalsItem = () => {
    return (
      <TableItemWrapper>
        <TableItemText>{title}</TableItemText>
        <TableItemText>{status}</TableItemText>
        {getItemWithIcon(emailIcon, email)}
        <TableItemText>{completedDate}</TableItemText>
        {/* <Button variant="outlined" size={BUTTON_SIZE.SMALL}>
          {t(keys.redeem)}
        </Button> */}
      </TableItemWrapper>
    );
  };

  if (tableType === TABLE_TYPE_ORDERS) {
    return getOrdersItems();
  } else if (tableType === TABLE_TYPE_WALLET_EARNINGS) {
    return getWalletEarningsItem();
  } else if (tableType === TABLE_TYPE_SUPPORT_LIST) {
    return getSupportListItem();
  } else if (tableType === TABLE_TYPE_RETAILERS) {
    return getRetailsItem();
  } else if (tableType === TABLE_TYPE_REFERALS) {
    return getReferalsItem();
  } else {
    <></>;
  }
};

export default TableItem;
