import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { CoinsHistoryActions } from ".";
import { getCoinsHistoryApi } from "./api";
import { CoinsHistoryRequest } from "./interface";

export default function* coinshistory(
  request: PayloadAction<CoinsHistoryRequest>,
): SagaIterator {
  try {
    const { payload } = request;
    const response = yield call(getCoinsHistoryApi, payload);
    const { data } = response;
    yield put(CoinsHistoryActions.success(data?.response));
  } catch (e) {
    yield put(CoinsHistoryActions.error(e?.response?.data?.message));
  }
}
