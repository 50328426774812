import { NotificationItemProps } from "./interface";
import {
  DateLabel,
  DescriptionLabel,
  NotificationItemWrapper,
  TitleLabel,
} from "./styled.components";

const NotificationItem = (props: NotificationItemProps) => {
  const { date, title, description } = props;

  return (
    <NotificationItemWrapper>
      <DateLabel>{date}</DateLabel>
      <TitleLabel>{title}</TitleLabel>
      <DescriptionLabel>{description}</DescriptionLabel>
    </NotificationItemWrapper>
  );
};

export default NotificationItem;
