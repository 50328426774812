import * as React from "react";

import {
  LargeLabel,
  MediumLabel,
  NormalBoldLabel,
  NormalLabel,
} from "./styled.components";

const Label = (props: any) => {
  const type = props.type;
  const margintop = props.marginTop ?? "0px";
  const params = { ...props, margintop };
  if (type === "large") {
    return <LargeLabel {...params}>{props.children}</LargeLabel>;
  } else if (type === "medium") {
    return <MediumLabel {...params}>{props.children}</MediumLabel>;
  } else if (type === "normal") {
    return <NormalLabel {...params}>{props.children}</NormalLabel>;
  } else if (type === "normalbold") {
    return <NormalBoldLabel {...params}>{props.children}</NormalBoldLabel>;
  }
  return <p>{props.children}</p>;
};

export default Label;
