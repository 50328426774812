import { RewardsPopoverProps } from "./interface";
import {
  PopoverWrapper,
  RewardItemText,
  RewardItemWrapper,
} from "./styled.components";

const RewardsPopover = (props: RewardsPopoverProps) => {
  const { achievementTierDetails } = props;

  return (
    <PopoverWrapper>
      {achievementTierDetails.map((item) => {
        return (
          <RewardItemWrapper key={item.id}>
            <RewardItemText align="start">{item.name}</RewardItemText>
            <RewardItemText times={true}>
              {item.pointsMultiplier}
            </RewardItemText>
            <RewardItemText>X &nbsp; Reward Points</RewardItemText>
          </RewardItemWrapper>
        );
      })}
    </PopoverWrapper>
  );
};

export default RewardsPopover;
