import { loginUserApi } from "data/slices/login/api";
import { LoginResponseProps } from "data/slices/login/interface";
import keys from "locale/keys";
import { isEmpty, isUndefined } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { DASHBOARD_DEFAULT_ROUTE, ROUTES } from "utils/constants";
import { saveTokens } from "utils/Utils";
import PURE from "widgets/prelogin/login";

import { LoginContainerProps } from "../interface";

const LoginContainer = (props: LoginContainerProps) => {
  const { onShowSnackbarHandler } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onLoginButtonClickedHandler = (username: string, password: string) => {
    loginUser(username, password);
  };

  const loginUser = async (username: string, password: string) => {
    setIsLoading(true);
    const loginResponse: LoginResponseProps = await loginUserApi({
      email: username,
      password,
    });

    setIsLoading(false);
    if (isUndefined(loginResponse) || isEmpty(loginResponse)) {
      onShowSnackbarHandler(t(keys.generic_error));
    } else if (!isEmpty(loginResponse.error)) {
      onShowSnackbarHandler(loginResponse.message);
    } else {
      saveTokens(loginResponse.response);
      loginResponse.response?.isFirstLogin
        ? navigate(ROUTES.WELCOME, {
            replace: true,
          })
        : navigate(DASHBOARD_DEFAULT_ROUTE, {
            replace: true,
          });
    }
  };

  const onForgotPasswordClickedHandler = () => {
    navigate(ROUTES.FORGOT_PASSWORD);
  };

  const onSingupButtonClickedHandler = () => {
    navigate(ROUTES.SIGNUP);
  };

  return (
    <PURE
      onForgotPasswordHandler={onForgotPasswordClickedHandler}
      onLoginHandler={onLoginButtonClickedHandler}
      onSignupHandler={onSingupButtonClickedHandler}
      isLoading={isLoading}
    />
  );
};

export default LoginContainer;
