import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";

import { ChangePasswordRequest, ResetPasswordRequest } from "./interface";

//from prelogin flow
export const resetPasswordApi = async (payload: ResetPasswordRequest) => {
  try {
    const { data } = await axios.post(
      getBaseURL() + ENDPOINTS.RESET_PASSWORD,
      payload,
    );

    return data;
  } catch (e) {
    return e.response?.data;
  }
};

//from profile settings
export const changePasswordApi = async (payload: ChangePasswordRequest) => {
  try {
    const { data } = await axios.post(
      getBaseURL() + ENDPOINTS.CHANGE_PASSWORD,
      payload,
      {
        headers: getHeaders(),
      },
    );

    return data;
  } catch (e) {
    return e.response?.data;
  }
};
