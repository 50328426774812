import { Button, Divider, Menu, Stack } from "@mui/material";
import Label from "components/label";
import keys from "locale/keys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MENU_ITEM_LOGOUT, MENU_ITEM_PROFILE_SETTINGS } from "utils/constants";

import DownArrow from "../../assets/icons/CaretDown.svg";
import Logout from "../../assets/icons/Logout.svg";
import User from "../../assets/icons/User.svg";
import UserCircle from "../../assets/icons/UserCircle.svg";
import { ProfileProps } from "./interface";
import {
  ButtonWrapper,
  CustomMenuItem,
  DownIcon,
  ProfileName,
  ProfilePic,
} from "./styled.components";

const Profile = (props: ProfileProps) => {
  const { onMenuItemSelected, name, showMenuOptions, profileUrl } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (selectedItem: number) => {
    setAnchorEl(null);
    onMenuItemSelected(selectedItem);
  };

  //dont show the menu options in welcome screen
  if (!showMenuOptions) {
    return (
      <Stack direction="row" alignItems="center" gap="2px">
        <ProfilePic src={UserCircle} alt="profile-pic" />
        <ProfileName>{name}</ProfileName>
      </Stack>
    );
  }

  const profilePic = profileUrl ? profileUrl : UserCircle;

  return (
    <>
      <ButtonWrapper>
        <Button style={{ textTransform: "none" }} onClick={handleClick}>
          <Stack direction="row" alignItems="center" gap="2px">
            <ProfilePic
              src={profilePic}
              alt="profile pic"
              loading="lazy"
              onError={(e) => {
                e.currentTarget.src = UserCircle;
              }}
            />
            <ProfileName>{name}</ProfileName>
            <DownIcon src={DownArrow} alt="down-arrow" />
          </Stack>
        </Button>
      </ButtonWrapper>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <CustomMenuItem onClick={() => handleClose(MENU_ITEM_PROFILE_SETTINGS)}>
          <Stack direction="row" alignItems="center" gap="8px">
            <DownIcon src={User} />
            <Label type="normal">{t(keys.profile_settings)}</Label>
          </Stack>
        </CustomMenuItem>
        <Divider />
        <CustomMenuItem onClick={() => handleClose(MENU_ITEM_LOGOUT)}>
          <Stack direction="row" alignItems="center" gap="8px">
            <DownIcon src={Logout} />
            <Label type="normal">{t(keys.logout)}</Label>
          </Stack>
        </CustomMenuItem>
      </Menu>
    </>
  );
};

export default Profile;
