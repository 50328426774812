import styled from "@emotion/styled";
import { css, Typography } from "@mui/material";
import { smallMobile } from "commons/styled.components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;
`;

export const ValidationWrapper = styled.div<{ isMatched?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  color: ${(props) => (props.isMatched ? "#0ea30e" : "#ABB8C3")};
`;

export const PrimaryLabel = styled(Typography)<{ isActive?: boolean }>`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;

  ${smallMobile(css`
    font-size: 1rem;
  `)}
`;

export const SecondaryLabel = styled(Typography)<{ isActive?: boolean }>`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
`;
