import { CartListItem } from "data/slices/cartlist/interface";
import { appendPoints } from "utils/Utils";
import { CartItemProps } from "widgets/dashboard/cart/interface";

export const getCartList = (cartlist: CartListItem[]) => {
  const cartItems: CartItemProps[] = [];

  var total = 0;
  if (cartlist) {
    cartlist.forEach((item) => {
      cartItems.push({
        id: item.id,
        title: item.MasterGiftCard.title,
        value: `£ ${item.MasterGiftCard.value}`,
        coins: appendPoints(item.coins),
        quantity: item.quantity,
        instock: item.MasterGiftCard.inStock,
      });

      total = total + item.coins;
    });
  }
  return { items: cartItems, total };
};
