import { Tokens } from "commons/interface";
import { WalletResponse } from "data/slices/wallet/interface";
import { format } from "date-fns";
import { Dayjs } from "dayjs";
import { isEmpty, isEqual, isUndefined } from "lodash";

import {
  DEFAULT_PLACEAHOLDER,
  DEFAULT_TERMS_CHARS_TO_SHOW,
  PROFILE_DOB_MIN_AGE,
  STATUS_TEST,
  STATUS_TYPE,
  STORAGE_IS_FIRST_LOGIN,
  STORAGE_REFRESH_TOKEN,
  STORAGE_TOKEN,
} from "./constants";

export const saveToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const saveTokens = (tokens: Tokens) => {
  saveToLocalStorage(STORAGE_TOKEN, tokens.token);
  saveToLocalStorage(STORAGE_REFRESH_TOKEN, tokens.refreshToken);
  saveToLocalStorage(STORAGE_IS_FIRST_LOGIN, String(tokens.isFirstLogin));
};

export const getTokens = (): Tokens => {
  return {
    token: getFromLocalStorage(STORAGE_TOKEN),
    refreshToken: getFromLocalStorage(STORAGE_REFRESH_TOKEN),
    isFirstLogin: Boolean(getFromLocalStorage(STORAGE_IS_FIRST_LOGIN)),
  };
};

export const logout = () => {
  localStorage.clear();
};

export const hasLoginTokens = (): boolean => {
  const tokens = getTokens();
  return tokens.refreshToken !== null && tokens.token !== null;
};

export const formatDate = (inputDate: string, toFormat: string) => {
  if (isUndefined(inputDate) || isEmpty(inputDate)) {
    return DEFAULT_PLACEAHOLDER;
  }

  var inDate = inputDate;
  if (inDate && inDate.includes("T")) {
    inDate = inDate.replace("T", " ");
  }

  const date = new Date(inDate);
  return format(date, toFormat);
};

export const getDateFormat = (date?: Dayjs | null) => {
  if (!date || date === null) {
    return "";
  }

  return date.format("DD MMM YYYY");
  //return `${date.month() + 1}/${date.day()}/${date.year()}`;
};

export const getStringWithDefaultValue = (input?: string) => {
  if (isUndefined(input) || isEmpty(input)) {
    return DEFAULT_PLACEAHOLDER;
  }
  return input;
};

export const getSurveyURLToOpen = (url: string, guid: string, key: string) => {
  //stage.members.precisionsample.com/survey/entry.aspx?mid=[%MID%]&project=1010000002&key=%key%
  var updatedUrl = url.replace("[%MID%]", guid);
  updatedUrl = updatedUrl.replace("%%key%%", key);
  return updatedUrl;
};

export const getCouponStatusType = (status: string) => {
  if (!status) {
    return undefined;
  }

  var ipstatus = status.toLowerCase();
  if (
    isEqual(ipstatus, STATUS_TEST.SUCCESS.toLowerCase()) ||
    isEqual(ipstatus, STATUS_TEST.RESOLVED.toLowerCase())
  ) {
    return STATUS_TYPE.SUCCESS;
  } else if (isEqual(ipstatus, STATUS_TEST.PENDING.toLowerCase())) {
    return STATUS_TYPE.PENDING;
  } else if (isEqual(ipstatus, STATUS_TEST.FAILED.toLowerCase())) {
    return STATUS_TYPE.FAILED;
  } else if (isEqual(ipstatus, STATUS_TEST.OPEN.toLowerCase())) {
    return STATUS_TYPE.OPEN;
  } else {
    return undefined;
  }
};

export const walletBalance = (walletdetails: WalletResponse) => {
  return walletdetails
    ? parseFloat((walletdetails.earnings - walletdetails.redeemed).toFixed(2))
    : 0;
};

export const appendPoints = (points: number) => {
  return `${points}pts`;
};

//will return date in yyyy-MM-dd format and also the day
export const getDateInFormat = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const monthName = date.toLocaleString("default", { month: "long" });
  return {
    formatted: `${year}-${month}-${day}`,
    day: day,
    tooltip: `${monthName} ${day}`,
    month: monthName,
  };
};

export const decrementOneDay = (date: Date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - 1);
  return newDate;
};

export const isStringEmpty = (input: string) => {
  return isUndefined(input) || isEmpty(input);
};

export const getUserName = (firstName: string, lastName: string) => {
  if (!firstName && !lastName) {
    return DEFAULT_PLACEAHOLDER;
  }

  return `${firstName} ${lastName?.charAt(0) ?? ""}`;
};

export const isValidAge = (selectedDate: Date) => {
  const minDate = new Date(new Date());
  minDate.setFullYear(minDate.getFullYear() - PROFILE_DOB_MIN_AGE);
  return selectedDate <= minDate;
};

export const termsTextToShow = (terms: string, showFull: boolean) => {
  if (showFull) {
    return terms;
  }

  return terms.length <= DEFAULT_TERMS_CHARS_TO_SHOW
    ? terms
    : terms.substring(0, DEFAULT_TERMS_CHARS_TO_SHOW);
};
