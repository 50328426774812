import { Divider } from "@mui/material";
import HeaderWithFilter from "components/headerwithfilter";
import CustomSnackbar from "components/snackbar";
import { State } from "data/reducers/state";
import { getInviteesList, sendUserInvitation } from "data/slices/referals/api";
import {
  InvitationListResponse,
  InvitationListRowResponse,
  UserInvitationRequest,
  UserInvitationResponse,
} from "data/slices/referals/interface";
import { UserDetailsStateProps } from "data/slices/userdetails/interface";
import keys from "locale/keys";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SNACKBAR_TYPE, USER_INVITATION_MASTER_CODE } from "utils/constants";
import { getReferalsList } from "utils/mappers/ReferalsMapper";
import Referals from "widgets/dashboard/referals";

import { Wrapper } from "../styled.components";

const ReferalContainer = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingReferalsList, setFetchingReferalsList] = useState(false);
  const [inviteesRows, setInviteesRows] = useState<InvitationListRowResponse[]>(
    [],
  );
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({
    msg: "",
    type: SNACKBAR_TYPE.ERROR,
  });

  const { details } = useSelector<State, UserDetailsStateProps>(
    (state) => state.userdetails,
  );

  useEffect(() => {
    fetchInviteesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInviteesList = async () => {
    setFetchingReferalsList(true);
    const response: InvitationListResponse = await getInviteesList(details.id);

    setFetchingReferalsList(false);
    if (isUndefined(response) || isUndefined(response?.response)) {
      setInviteesRows([]);
    } else {
      setInviteesRows(response?.response?.rows);
    }
  };

  const sendUserInvite = (name: string, email: string) => {
    //until ui change, sending name for both first and last name
    sendInvite({
      firstName: name,
      lastName: name,
      masterCode: USER_INVITATION_MASTER_CODE,
      email: email,
      invitedBy: details.id,
    });
  };

  const sendInvite = async (request: UserInvitationRequest) => {
    setIsLoading(true);
    const response: UserInvitationResponse = await sendUserInvitation(request);

    setIsLoading(false);
    if (isUndefined(response.error)) {
      //success
      fetchInviteesList();
      onShowSnackbarHandler(response.message, SNACKBAR_TYPE.SUCCESS);
    } else {
      onShowSnackbarHandler(response.message, SNACKBAR_TYPE.ERROR);
    }
  };

  const onShowSnackbarHandler = (msg: string, type: number) => {
    setShowSnackbar(true);
    setSnackbarMsg({ msg, type });
  };

  const referalsList = getReferalsList(inviteesRows);

  return (
    <>
      <HeaderWithFilter title={t(keys.refer_users)} showFilter={false} />
      <Divider />
      <Wrapper>
        <Referals
          referalsList={referalsList}
          sendUserInvite={sendUserInvite}
          isLoading={isLoading}
          fetchingReferalsList={fetchingReferalsList}
          resetInput={showSnackbar}
        />
      </Wrapper>
      <CustomSnackbar
        msg={snackbarMsg.msg}
        type={snackbarMsg.type}
        show={showSnackbar}
        dismiss={() => setShowSnackbar(false)}
      />
    </>
  );
};

export default ReferalContainer;
