import styled from "@emotion/styled";
import { ButtonGroup, Typography } from "@mui/material";
import { COLORS } from "utils/colors";

export const QuantityButtonGroup = styled(ButtonGroup)`
  background: ${COLORS.BRAND_DEFAULT_COLOR};
  color: white;
`;

export const CountText = styled(Typography)`
  color: white;
  text-align: center;
  align-content: center;
  width: 16px;
  font-size: 0.75rem;
`;
