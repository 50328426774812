import { PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { GiftCardsActions } from ".";
import { getGiftCardsApi } from "./api";
import { GiftCardsRequest } from "./interface";

export default function* giftcards(
  request: PayloadAction<GiftCardsRequest>,
): SagaIterator {
  try {
    const { payload } = request;
    const giftcardsResponse = yield call(getGiftCardsApi, payload);
    const { data } = giftcardsResponse;
    yield put(GiftCardsActions.success(data?.response));
  } catch (e) {
    yield put(GiftCardsActions.error(e?.response?.data?.message));
  }
}
