export const COLORS = {
  LOGIN_PRIMARY: "#000000",
  LOGIN_SECONDARY: "#727272",
  LINK: "#4976f4",
  INPUT_TEXT: "#4a4543",
  INPUT_TEXT_ERROR: "#AF4B4B",
  INPUT_PLACEHOLDER_TEXT: "#AFAFAF",
  BANNER_BG: "#d0e8e3",

  BRAND_DEFAULT_COLOR: "#158A73",
  BRAND_SECONDARY_COLOR: "#3C9D8A",
  BRAND_LIGHT_BACKGROUND: "#EAF0EF",
};
