import { BaseStateProps } from "data/interface";

export interface CartListResponse {
  message: string;
  response: CartListItem[];
}

export interface CartListItem {
  id: number;
  cartId: number;
  masterGiftCard: number;
  coins: number;
  quantity: number;
  MasterGiftCard: {
    title: string;
    value: number;
    coins: number;
    inStock: boolean;
  };
}

export interface CartListStateProps extends BaseStateProps {
  cartlist: CartListItem[];
  isError: boolean;
}

export const initialState: CartListStateProps = {
  cartlist: [],
  isError: false,
  isFetching: false,
};
