import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

export const EmptyErrorImage = styled.img<{ largesize?: boolean }>`
  width: ${(props) => (props.largesize ? "20rem" : "15rem")};
  height: ${(props) => (props.largesize ? "22.5rem" : "15rem")};
`;

export const ErrorMessageText = styled(Typography)`
  font-size: 14px;
  text-align: center;
  font-weight: 500;
`;
