import { Divider } from "@mui/material";
import Banner from "components/banner";
import HeaderWithFilter from "components/headerwithfilter";
import CustomSnackbar from "components/snackbar";
import { State } from "data/reducers/state";
import { SurveyListActions } from "data/slices/surveylist";
import { surveyIncreaseClicks } from "data/slices/surveylist/api";
import {
  IncreaseClickResponse,
  SurveyListStateProps,
} from "data/slices/surveylist/interface";
import keys from "locale/keys";
import { isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  MAX_SURVEYS_IN_DASHBOARD,
  SNACKBAR_TYPE,
  SURVEY_STATUS_ASSIGNED,
  SURVEY_STATUS_COMPLETED,
} from "utils/constants";
import { surveysMapper } from "utils/mappers/SurveysListMapper";
import SURVEYSPURE from "widgets/dashboard/surveys";
import { SurveySetProps } from "widgets/dashboard/surveys/interface";
import NotificationBanner from "widgets/dashboard/surveys/NotificationBanner";

import { SurveysProps } from "../interface";
import { Wrapper } from "../styled.components";
import { ALLSURVEYSPURE } from "./AllSurveysContainer";

const SurveysContainer = (props: SurveysProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showAllSurveys, setShowAllSurveys] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showingCompletedSurveys, setShowingCompletedSurveys] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({
    msg: "",
    type: SNACKBAR_TYPE.ERROR,
  });

  const {
    isFetchingNewSurveys,
    isFetchingCompletedSurveys,
    newsurveys,
    completedsurveys,
    isError,
  } = useSelector<State, SurveyListStateProps>((state) => state.surveylist);

  //in the main page, just fetch the initial surveys to show. If more are present, then show view more option to see all other surveys
  useEffect(() => {
    //new surveys
    dispatch(
      SurveyListActions.request({
        status: SURVEY_STATUS_ASSIGNED,
        pageSize: MAX_SURVEYS_IN_DASHBOARD + 1,
        page: 0,
      }),
    );

    //completed surveys
    dispatch(
      SurveyListActions.request({
        status: SURVEY_STATUS_COMPLETED,
        pageSize: MAX_SURVEYS_IN_DASHBOARD + 1,
        page: 0,
      }),
    );
  }, [dispatch]);

  const newSurveys: SurveySetProps = surveysMapper(
    newsurveys.rows,
    false,
    isError,
    newsurveys.count,
  );
  const completedSurveys: SurveySetProps = surveysMapper(
    completedsurveys.rows,
    true,
    isError,
    completedsurveys.count,
  );

  const onViewMoreClicked = (isCompletedSurveys: boolean) => {
    setShowAllSurveys(true);
    setShowingCompletedSurveys(isCompletedSurveys);
  };

  const onAllSurveysBackClicked = () => {
    setShowAllSurveys(false);
  };

  const dismissNotification = () => {
    setShowNotification(false);
  };

  const increaseClicks = async (surveyId: number) => {
    setIsLoading(true);

    const response: IncreaseClickResponse =
      await surveyIncreaseClicks(surveyId);

    setIsLoading(false);
    if (response && !isEmpty(response?.link) && !isUndefined(response?.link)) {
      openLink(response.link);
    } else {
      setShowSnackbar(true);
      setSnackbarMsg({
        msg: t(keys.survey_link_fetch_failed),
        type: SNACKBAR_TYPE.ERROR,
      });
    }
  };

  const onStartSurveyClicked = (url: string, key: string, id: number) => {
    increaseClicks(id);
  };

  const openLink = (link: string) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      {showAllSurveys && (
        <ALLSURVEYSPURE
          showingCompletedSurveys={showingCompletedSurveys}
          onAllSurveysBackClicked={onAllSurveysBackClicked}
          onStartSurveyClicked={onStartSurveyClicked}
          isLoading={isLoading}
        />
      )}
      {!showAllSurveys && (
        <>
          {showNotification && (
            <Banner>
              <NotificationBanner
                date="Dec 28, 2023"
                title="Survey Invitations"
                description="New Survey Available! Earn 50 points by completing this 10-minute survey on consumer habits."
                dismissNotification={dismissNotification}
              />
            </Banner>
          )}
          <HeaderWithFilter title={t(keys.my_surveys)} showFilter={false} />
          <Divider />
          <Wrapper>
            <SURVEYSPURE
              newSurveys={newSurveys}
              completedSurveys={completedSurveys}
              onViewMoreClicked={onViewMoreClicked}
              isNewSurveysLoading={isFetchingNewSurveys}
              isCompletedSurveysLoading={isFetchingCompletedSurveys}
              onStartSurveyClicked={onStartSurveyClicked}
              isError={isError}
              isLoading={isLoading}
            />
          </Wrapper>
          <CustomSnackbar
            msg={snackbarMsg.msg}
            type={snackbarMsg.type}
            show={showSnackbar}
            dismiss={() => setShowSnackbar(false)}
          />
        </>
      )}
    </>
  );
};

export default SurveysContainer;
