import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";

import { ForgotPasswordRequest } from "./interface";

export const forgotPasswordApi = async (payload: ForgotPasswordRequest) => {
  try {
    const { data } = await axios.post(
      getBaseURL() + ENDPOINTS.FORGOT_PASSWORD,
      payload,
    );

    return data;
  } catch (e) {
    return e.response?.data;
  }
};
