import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { isSmallDevice$ } from "common.service";
import DashboardContent from "containers/dashboard/DashboardContent";
import * as React from "react";
import { STORAGE_SELECTED_MENU } from "utils/constants";
import { saveToLocalStorage } from "utils/Utils";

import ApplogoBlack from "../../assets/icons/ApplogoBlack.svg";
import expand from "../../assets/icons/sidedrawer/sidebar_expand.svg";
import survey_logo from "../../assets/icons/survey_logo.png";
import { SideDrawerProps } from "./interface";
import {
  CustomToolbar,
  DrawerContent,
  DrawerExpand,
  IconButtonWrapper,
  ItemImage,
  NotificationCountlabel,
  SideNavWrapper,
} from "./styled.components";
import TopBar from "./Topbar";
import { SIDEDRAWER_ITEMS } from "./utils";

const drawerWidth = 248;
const drawerHeaderHeight = 72;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: drawerHeaderHeight,
  padding: 20,
  borderBottom: "1px solid #0000001f",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: "white",
  height: drawerHeaderHeight,
  boxShadow: "none",
  borderBottom: "1px solid #0000001f",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100%",
      justifyContent: "center",
    },
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  background: "#F9F9F9 !important",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props: SideDrawerProps) {
  const { onSidebarItemSelected, onMenuItemSelected, selectedMenu } = props;
  const [open, setOpen] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState(selectedMenu);
  const [isSmallDevice, setSmallDevice] = React.useState<boolean>(false);

  const updateSidedrawerItem = (selectItem: number) => {
    saveToLocalStorage(STORAGE_SELECTED_MENU, String(selectItem));
    setSelectedItem(selectItem);
  };

  const toogleOpen = () => {
    setOpen(!open);
  };

  const onItemSelected = (index: number) => {
    saveToLocalStorage(STORAGE_SELECTED_MENU, String(index));
    setSelectedItem(index);
    onSidebarItemSelected(SIDEDRAWER_ITEMS[index].route);
  };

  /* const onFooterItemSelected = (index: number) => {
    setSelectedItem(SIDEDRAWER_ITEMS.length + index);
    onSidebarItemSelected(SIDEDRAWER_FOOTER_ITEMS[index].route);
  }; */

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (
      (
        window.performance.getEntriesByType(
          "navigation",
        )[0] as PerformanceNavigationTiming
      ).type === "reload"
    ) {
      handleResize();
    }
    const subscription = isSmallDevice$.subscribe(setSmallDevice);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  const handleResize = () => {
    if (window.innerWidth > 768) {
      setSmallDevice(false);
    } else {
      setSmallDevice(true);
    }
  };

  const getListItem = (title: string, index: number) => {
    return (
      <>
        <ListItemText
          primary={title}
          sx={{
            opacity: open ? 1 : 0,
            paddingLeft: "12px",
            "& .MuiTypography-root": {
              fontSize: "14px",
              fontWeight: "500",
              color: selectedItem === index ? "#FFF" : "#000000",
            },
          }}
        />
        {index === 5 && <NotificationCountlabel>12</NotificationCountlabel>}
      </>
    );
  };

  const collapseContainerStyle = {
    marginLeft: "60px",
  };

  const expandedContainerStyle = {
    marginLeft: "240px",
  };

  function content() {
    return (
      <>
        <CssBaseline />
        <AppBar position="fixed" open={open} data-testid="appbar">
          <CustomToolbar>
            <IconButtonWrapper
              onClick={toogleOpen}
              style={{
                transform: open ? "Translatex(-46px)" : "Translatex(-15px)",
                backgroundColor: "rgba(0,0,0,0)",
              }}
            >
              {!open && (
                <img
                  src={survey_logo}
                  alt="eye ball"
                  style={{
                    paddingRight: "10px",
                    width: "2.6rem",
                    height: "2.2rem",
                  }}
                />
              )}
              <DrawerExpand src={expand} alt="sidedrawer expand" />
            </IconButtonWrapper>
            <TopBar onMenuItemSelected={onMenuItemSelected} />
          </CustomToolbar>
        </AppBar>
        <SideNavWrapper>
          <Drawer variant="permanent" open={open} data-testid="drawer">
            <DrawerContent>
              <DrawerHeader>
                <img
                  src={ApplogoBlack}
                  alt="side drawer app logo"
                  style={{
                    width: "12rem",
                  }}
                />
              </DrawerHeader>
              <List>
                {SIDEDRAWER_ITEMS.map((item, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: "block", padding: "0px 8px" }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          selectedItem === index ? "#158A73" : "",
                        borderRadius: "4px",
                      }}
                    >
                      <ListItemButton
                        selected={selectedItem === index}
                        disabled={!item.enabled}
                        onClick={() => onItemSelected(index)}
                        sx={{
                          minHeight: 36,
                          justifyContent: open ? "initial" : "center",
                          backgroundColor:
                            selectedItem === index ? "#158A73" : "transparent", // Background color for selected item
                        }}
                      >
                        <ItemImage
                          src={item.icon}
                          alt="side drawer item icon"
                          className={selectedItem === index ? "ItemColor" : ""}
                        />
                        {getListItem(item.title, index)}
                      </ListItemButton>
                    </div>
                  </ListItem>
                ))}
              </List>
              {/* <FooterList>
              {SIDEDRAWER_FOOTER_ITEMS.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ display: "block", padding: "0px 8px" }}
                >
                  <ListItemButton
                    selected={selectedItem === SIDEDRAWER_ITEMS.length + index}
                    onClick={() => onFooterItemSelected(index)}
                    sx={{
                      minHeight: 36,
                      justifyContent: open ? "initial" : "center",
                    }}
                  >
                    <img src={item.icon} alt="side drawer item icon" />
                    {getListItem(item.title)}
                  </ListItemButton>
                </ListItem>
              ))}
            </FooterList> */}
            </DrawerContent>
          </Drawer>
        </SideNavWrapper>
        {/* <Box component="main" sx={{ flexGrow: 1 }}> */}
        {!isSmallDevice && (
          <div style={open ? expandedContainerStyle : collapseContainerStyle}>
            <DrawerHeader />
            <DashboardContent updateSidedrawerItem={updateSidedrawerItem} />
          </div>
        )}
        {isSmallDevice && (
          <>
            <DrawerHeader />
            <DashboardContent updateSidedrawerItem={updateSidedrawerItem} />
          </>
        )}
      </>
    );
  }

  return <> {content()}</>;
}
