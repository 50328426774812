import "./i18n";

import { isSmallDevice$ } from "common.service";
import configureAppStore from "data/store";
import React from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import routes from "./Routes";

const App = () => {
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (
      (
        window.performance.getEntriesByType(
          "navigation",
        )[0] as PerformanceNavigationTiming
      ).type === "reload"
    ) {
      handleResize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  const handleResize = () => {
    if (window.innerWidth > 768) {
      isSmallDevice$.next(false);
    } else {
      isSmallDevice$.next(true);
    }
  };
  return (
    <Provider store={configureAppStore}>
      <RouterProvider router={routes} />
    </Provider>
  );
};

export default App;
