import {
  getInvitationDetailsFromHash,
  signupApi,
} from "data/slices/signup/api";
import {
  InvitationHashResponse,
  SignupResponse,
} from "data/slices/signup/interface";
import keys from "locale/keys";
import { isEmpty, isEqual, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "utils/constants";
import { saveTokens } from "utils/Utils";
import PURE from "widgets/prelogin/signup";

import { LoginContainerProps } from "../interface";

const SignupContainer = (props: LoginContainerProps) => {
  const { onShowSnackbarHandler } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSignupSuccess, setIsSignupSuccess] = useState(false);
  const [isAccountAlreadyExists, setIsAccountAlreadyExists] = useState(false);
  const [hashDetails, setHashDetails] = useState({});

  useEffect(() => {
    if (!isUndefined(params.id) && !isEmpty(params.id)) {
      fetchDetailsFromHash(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const fetchDetailsFromHash = async (id: string) => {
    setIsLoading(true);
    const details: InvitationHashResponse = await getInvitationDetailsFromHash(
      params.id,
    );

    setIsLoading(false);
    if (!isUndefined(details) && !isUndefined(details.response)) {
      setHashDetails(details);
    }
  };

  const onSignupButtonClickedHandler = (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
  ) => {
    signupUser(firstName, lastName, email, password);
  };

  const signupUser = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
  ) => {
    setIsLoading(true);
    const signupResponse: SignupResponse = await signupApi({
      firstName,
      lastName,
      email,
      password,
      role: "User",
      hash: isEqual(params.id, ":id") ? null : params.id,
    });

    setIsLoading(false);
    if (isUndefined(signupResponse)) {
      onShowSnackbarHandler(t(keys.generic_error));
    } else if (!isEmpty(signupResponse.error)) {
      //todo, for now checking based on string. Should be based on a field in response
      if (isEqual(signupResponse.message, "email must be unique")) {
        setIsAccountAlreadyExists(true);
      } else {
        onShowSnackbarHandler(signupResponse.message);
      }
    } else {
      saveTokens(signupResponse.response);
      setIsSignupSuccess(true);
    }
  };

  const onLoginClickedHandler = () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <PURE
      onSignupHandler={onSignupButtonClickedHandler}
      isLoading={isLoading}
      isSignupSuccess={isSignupSuccess}
      onLoginClickedHandler={onLoginClickedHandler}
      isAccountAlreadyExists={isAccountAlreadyExists}
      details={hashDetails}
    />
  );
};

export default SignupContainer;
