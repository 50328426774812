import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { CartListActions } from ".";
import { getCartListApi } from "./api";

export default function* cartlist(): SagaIterator {
  try {
    const cartlistResponse = yield call(getCartListApi);
    const { data } = cartlistResponse;
    yield put(CartListActions.success(data?.response));
  } catch (e) {
    yield put(CartListActions.error(e?.response?.data?.message));
  }
}
