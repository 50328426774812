import styled from "@emotion/styled";
import { Alert, Card, css, Typography } from "@mui/material";
import { COLORS } from "utils/colors";
import { MEDIA_SIZES, STATUS_TYPE } from "utils/constants";

export const StyledAlertCommon = styled(Alert)<{ isSuccess?: boolean }>`
  border-radius: 4px;
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid ${(props) => (props.isSuccess ? "#a3cfbb" : "#f1aeb5")};
  background: ${(props) => (props.isSuccess ? "#d1e7dd" : "#f8d7da")};
  color: ${(props) => (props.isSuccess ? "#146c43" : "#b02a37")};
`;

const getCapsuleBGColor = (type: number) => {
  if (type === STATUS_TYPE.SUCCESS) {
    return "#EEF5F0";
  } else if (type === STATUS_TYPE.PENDING) {
    return "#FBF4EC";
  } else if (type === STATUS_TYPE.OPEN) {
    return "#EDF2FE";
  } else {
    return "#F7EDED";
  }
};

const getCapsuleTextColor = (type: number) => {
  if (type === STATUS_TYPE.SUCCESS) {
    return "#589E67";
  } else if (type === STATUS_TYPE.PENDING) {
    return "#D28E3D";
  } else if (type === STATUS_TYPE.OPEN) {
    return "#4976F4";
  } else {
    return "#AF4B4B";
  }
};

export const CapsuleWrapper = styled.div<{ type: number }>`
  display: inline-block;
  padding: 4px 6px;
  border-radius: 4px;
  background: ${(props) => getCapsuleBGColor(props.type)};
`;

export const CapsuleText = styled(Typography)<{ type: number }>`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => getCapsuleTextColor(props.type)};
`;

export const InputFieldInputStyle = (isValid: boolean) => {
  return {
    style: {
      color: isValid ? COLORS.INPUT_TEXT : COLORS.INPUT_TEXT_ERROR,
    },
  };
};

export const mobile = (inner: any) => css`
  @media (max-width: ${MEDIA_SIZES.mobile}) {
    ${inner};
  }
`;

export const tablet = (inner: any) => css`
  @media (max-width: ${MEDIA_SIZES.tablet}) {
    ${inner};
  }
`;

export const mediumMobile = (inner: any) => css`
  @media (max-width: ${MEDIA_SIZES.mediumMobile}) {
    ${inner};
  }
`;

export const laptop = (inner: any) => css`
  @media (max-width: ${MEDIA_SIZES.laptop}) {
    ${inner};
  }
`;

export const smallMobile = (inner: any) => css`
  @media (max-width: ${MEDIA_SIZES.smallMobile}) {
    ${inner};
  }
`;

export const desktop = (inner: any) => css`
  @media (max-width: ${MEDIA_SIZES.desktop}) {
    ${inner};
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  background: #fcfcfc;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  min-width: 80% !important;
`;

export const MobileItemCard = styled(Card)`
  margin: 4px 2px;
  padding: 12px 8px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
`;

export const MobileCardTitle = styled(Typography)`
  text-wrap: wrap;
  font-size: 16px;
  padding-bottom: 8px;
  font-weight: 500;
`;
