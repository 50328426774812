import styled from "@emotion/styled";
import { css, MenuItem, Typography } from "@mui/material";
import { tablet } from "commons/styled.components";

export const ProfilePic = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
`;

export const ProfileName = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: black;
  padding-left: 4px;

  ${tablet(css`
    display: none;
  `)}
`;

export const DownIcon = styled.img`
  width: 16px;
  height: 16px;

  ${tablet(css`
    display: none;
  `)}
`;

export const CustomMenuItem = styled(MenuItem)`
  padding: 8px 12px;
`;

export const ButtonWrapper = styled.div`
  ${tablet(css`
    pointer-events: none;
  `)}
`;
