import styled from "@emotion/styled";
import { css, IconButton, Typography } from "@mui/material";
import { mediumMobile } from "commons/styled.components";

export const DialogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.75rem 3.75rem 2.5rem 3.75rem;
  gap: 1.5rem;
  align-items: center;

  ${mediumMobile(css`
    padding: 2rem 2.5rem 2rem 2rem;
  `)}
`;

export const MessageText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 400;
`;

export const ButtonWrapper = styled.span`
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const DialogCloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 0px;
`;
