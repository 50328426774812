import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { Dialog, DialogProps, IconButton, InputAdornment } from "@mui/material";
import Button from "components/button";
import { BUTTON_SIZE } from "components/button/interface";
import PasswordValidator from "components/passwordvalidation";
import keys from "locale/keys";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "utils/colors";
import { ChangePasswordValidationModel } from "widgets/prelogin/interface";
import { LoginTextField } from "widgets/prelogin/styled.components";

import { ChangePasswordDialogProps } from "./interface";
import {
  ButtonWrapper,
  ChangePasswordSubTitle,
  ChangePasswordTitle,
  ChangePasswordWrapper,
  DialogCloseButton,
  InputFieldWrapper,
} from "./styled.components";
import { ValidateChangeUserPassword } from "./utils";

const ChangePasswordDialog = (props: ChangePasswordDialogProps) => {
  const { show, dismiss } = props;
  const { t } = useTranslation();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [passwordStrengthValidated, setPasswordStrengthValidated] =
    React.useState(false);

  const [validationModel, setValidationModel] =
    React.useState<ChangePasswordValidationModel>({
      isValidPassword: true,
      isValidConfirmPassword: true,
    });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const onChangeClickedHandler = () => {
    const model = ValidateChangeUserPassword(
      password,
      confirmPassword,
      passwordStrengthValidated,
    );
    setValidationModel(model);
    if (model.isValidPassword && model.isValidConfirmPassword) {
      props.onPasswordChangeHandler(password, confirmPassword);
    }
  };

  const handleClose: DialogProps["onClose"] = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    dismiss();
  };

  return (
    <Dialog maxWidth="xs" fullWidth={true} open={show} onClose={handleClose}>
      <>
        <DialogCloseButton onClick={dismiss}>
          <Close />
        </DialogCloseButton>
        <ChangePasswordWrapper>
          <ChangePasswordTitle>{t(keys.change_password)}</ChangePasswordTitle>
          <InputFieldWrapper>
            <ChangePasswordSubTitle>
              {t(keys.old_password)}
            </ChangePasswordSubTitle>
            <LoginTextField
              error={!validationModel.isValidPassword}
              type={showPassword ? "text" : "password"}
              onInput={(e: any) => {
                setPassword(e.target.value);
              }}
              helperText={validationModel?.passwordMessage}
              InputProps={{
                placeholder: t(keys.enter_password),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  color: validationModel?.isValidPassword
                    ? COLORS.INPUT_TEXT
                    : COLORS.INPUT_TEXT_ERROR,
                },
              }}
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <ChangePasswordSubTitle>
              {t(keys.new_password)}
            </ChangePasswordSubTitle>
            <LoginTextField
              error={!validationModel.isValidConfirmPassword}
              type={showConfirmPassword ? "text" : "password"}
              onInput={(e: any) => {
                setConfirmPassword(e.target.value);
              }}
              InputProps={{
                placeholder: t(keys.enter_password),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={validationModel?.confirmPasswordMessage}
              inputProps={{
                style: {
                  color: validationModel?.isValidConfirmPassword
                    ? COLORS.INPUT_TEXT
                    : COLORS.INPUT_TEXT_ERROR,
                },
              }}
            />
          </InputFieldWrapper>
          <PasswordValidator
            password={confirmPassword}
            setPasswordStrengthValidated={setPasswordStrengthValidated}
          />
          <ButtonWrapper>
            <Button
              size={BUTTON_SIZE.MEDIUM}
              onClickHandler={onChangeClickedHandler}
            >
              {t(keys.change)}
            </Button>
          </ButtonWrapper>
        </ChangePasswordWrapper>
      </>
    </Dialog>
  );
};

export default ChangePasswordDialog;
