import { CartCountActions } from "data/slices/cartcount";
import cartcount from "data/slices/cartcount/saga";
import { CartListActions } from "data/slices/cartlist";
import cartlist from "data/slices/cartlist/saga";
import { CoinsHistoryActions } from "data/slices/coinshistory";
import coinshistory from "data/slices/coinshistory/saga";
import { FlexiCardsActions } from "data/slices/flexicarddetails";
import flexicards from "data/slices/flexicarddetails/saga";
import { GiftCardsActions } from "data/slices/giftcards";
import giftcards from "data/slices/giftcards/saga";
import { SupportTicketsAction } from "data/slices/support";
import supportTickets from "data/slices/support/saga";
import { SurveyListActions } from "data/slices/surveylist/";
import surveyList from "data/slices/surveylist/saga";
import { UserDetailsActions } from "data/slices/userdetails";
import userDetails from "data/slices/userdetails/saga";
import { WalletDetailsActions } from "data/slices/wallet";
import walletDetails from "data/slices/wallet/saga";
import { all, takeEvery, takeLatest } from "redux-saga/effects";

function* rootSaga() {
  yield all([takeLatest(UserDetailsActions.request.type, userDetails)]);
  yield all([takeEvery(SurveyListActions.request.type, surveyList)]);
  yield all([takeLatest(WalletDetailsActions.request.type, walletDetails)]);
  yield all([takeLatest(GiftCardsActions.request.type, giftcards)]);
  yield all([takeLatest(FlexiCardsActions.request.type, flexicards)]);
  yield all([takeLatest(CartCountActions.request.type, cartcount)]);
  yield all([takeLatest(CartListActions.request.type, cartlist)]);
  yield all([takeLatest(CoinsHistoryActions.request.type, coinshistory)]);
  yield all([takeLatest(SupportTicketsAction.request.type, supportTickets)]);
}

export default rootSaga;
