import styled from "@emotion/styled";
import { IconButton, Link, TextField, Typography } from "@mui/material";

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CustomLink = styled(Link)`
  font-size: 1rem;
  font-weight: 500;
`;

export const LinksWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;
  margin-top: 54px;
`;

export const ButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  background: white;
  display: flex;
  margin-top: 48px;
  justify-content: space-between;
  padding: 24px 0px;
`;

export const CustomTextField = styled(TextField)``;

export const ChangePasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 2rem;
`;

export const DialogCloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const ChangePasswordTitle = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 400;
`;

export const ChangePasswordSubTitle = styled(Typography)`
  font-size: 0.88rem;
  font-weight: 400;
`;

export const ButtonWrapper = styled.span`
  display: flex;
  justify-content: end;
  width: 100%;
  padding-top: 10px;
`;

export const ProfilePicWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  align-items: end;
`;

export const ProfilePic = styled.img`
  width: auto;
  height: auto;
  border: 1px solid #00000033;
  border-radius: 4px;
  max-width: 6.875rem;
  max-height: 6.875rem;
`;
