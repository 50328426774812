import { InputFieldInputStyle } from "commons/styled.components";
import Button from "components/button";
import Label from "components/label";
import PasswordValidator from "components/passwordvalidation";
import PasswordTextField from "components/textfield/PasswordTextField";
import withLoader from "hoc/loader";
import keys from "locale/keys";
import { useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "utils/colors";

import { SignupValidationModel } from "../interface";
import {
  BaseForm,
  InputFieldWrapper,
  LoginTextField,
  LoginWrapper,
  SignupNameWrapper,
} from "../styled.components";
import {
  validateEmail,
  validatePasswordForStrength,
  validateSignup,
  validateSimpleInput,
} from "../utils";
import { SignupProps } from "./interface";

const Signup = (props: SignupProps) => {
  const {
    isSignupSuccess,
    onLoginClickedHandler,
    onSignupHandler,
    isAccountAlreadyExists,
    details,
  } = props;
  const { t } = useTranslation();

  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordStrengthValidated, setPasswordStrengthValidated] =
    React.useState(false);
  const [validationModel, setValidationModel] = useState<SignupValidationModel>(
    {
      isValidFirstName: true,
      isValidLastName: true,
      isValidEmail: true,
      isValidPassword: true,
      isValidConfirmPassword: true,
    },
  );

  const detailsToPrefil = details?.response;
  React.useEffect(() => {
    if (detailsToPrefil) {
      setFirstName(detailsToPrefil?.firstName);
      setLastName(detailsToPrefil?.lastName);
      setEmail(detailsToPrefil?.email);
    }
  }, [detailsToPrefil]);

  const firstNameValidation = () => {
    const model = validateSimpleInput(firstname, keys.please_enter_firstname);
    setValidationModel({
      ...validationModel,
      isValidFirstName: model.isValid,
      firstNameMessage: model.message,
    });
  };

  const lastNameValidation = () => {
    const model = validateSimpleInput(lastname, keys.please_enter_lastname);
    setValidationModel({
      ...validationModel,
      isValidLastName: model.isValid,
      lastNameMessage: model.message,
    });
  };

  const emailValidation = () => {
    const model = validateEmail(email);
    setValidationModel({
      ...validationModel,
      isValidEmail: model.isValid,
      emailMessage: model.message,
    });
  };

  const passwordValidation = () => {
    const model = validatePasswordForStrength(
      password,
      passwordStrengthValidated,
    );
    setValidationModel({
      ...validationModel,
      isValidPassword: model.isValid,
      passwordMessage: model.message,
    });
  };

  const confirmPasswordValidation = () => {
    const model = validateSimpleInput(
      confirmPassword,
      keys.please_enter_confirm_password,
    );
    setValidationModel({
      ...validationModel,
      isValidConfirmPassword: model.isValid,
      confirmPasswordMessage: model.message,
    });
  };

  const onSignupButtonClicked = (event: any) => {
    event.preventDefault();
    const model = validateSignup(
      firstname,
      lastname,
      email,
      password,
      confirmPassword,
      passwordStrengthValidated,
    );
    setValidationModel(model);
    if (
      model.isValidFirstName &&
      model.isValidLastName &&
      model.isValidEmail &&
      model.isValidPassword &&
      model.isValidConfirmPassword
    ) {
      onSignupHandler(firstname, lastname, email, password);
    }
  };

  if (isSignupSuccess || isAccountAlreadyExists) {
    const msg = isAccountAlreadyExists
      ? t(keys.account_exists)
      : t(keys.successfully_signup);
    return (
      <LoginWrapper>
        <Label type="large" color={COLORS.LOGIN_PRIMARY}>
          {t(keys.successful)}
        </Label>
        <Label type="medium" color={COLORS.LOGIN_SECONDARY}>
          {msg}
        </Label>
        <Button onClickHandler={onLoginClickedHandler}>{t(keys.login)}</Button>
      </LoginWrapper>
    );
  }

  return (
    <BaseForm onSubmit={onSignupButtonClicked}>
      <LoginWrapper>
        <Label type="large" color={COLORS.LOGIN_PRIMARY}>
          {t(keys.sign_up_title)}
        </Label>
        <Label type="medium" color={COLORS.LOGIN_SECONDARY}>
          {t(keys.welcome_enter_your_details)}
        </Label>
        <SignupNameWrapper>
          <InputFieldWrapper>
            <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
              {t(keys.first_name)}
            </Label>
            <LoginTextField
              defaultValue={detailsToPrefil?.firstName ?? ""}
              value={firstname}
              error={!validationModel?.isValidFirstName}
              placeholder={t(keys.enter_first_name)}
              type="text"
              helperText={validationModel?.firstNameMessage}
              onInput={(e: any) => {
                setFirstName(e.target.value);
              }}
              onBlur={() => firstNameValidation()}
              inputProps={InputFieldInputStyle(
                validationModel?.isValidFirstName,
              )}
              disabled
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
              {t(keys.last_name)}
            </Label>
            <LoginTextField
              defaultValue={detailsToPrefil?.lastName ?? ""}
              value={lastname}
              error={!validationModel?.isValidLastName}
              placeholder={t(keys.enter_last_name)}
              type="text"
              helperText={validationModel?.lastNameMessage}
              onInput={(e: any) => {
                setLastName(e.target.value);
              }}
              onBlur={() => lastNameValidation()}
              inputProps={InputFieldInputStyle(
                validationModel?.isValidLastName,
              )}
              disabled
            />
          </InputFieldWrapper>
        </SignupNameWrapper>
        <InputFieldWrapper>
          <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
            {t(keys.email)}
          </Label>
          <LoginTextField
            defaultValue={detailsToPrefil?.email ?? ""}
            value={email}
            error={!validationModel?.isValidEmail}
            placeholder={t(keys.enter_email)}
            type="email"
            helperText={validationModel?.emailMessage}
            onInput={(e: any) => {
              setEmail(e.target.value);
            }}
            onBlur={() => emailValidation()}
            inputProps={InputFieldInputStyle(validationModel?.isValidEmail)}
            disabled
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
            {t(keys.password)}
          </Label>
          <PasswordTextField
            placeholder={t(keys.enter_password)}
            error={!validationModel?.isValidPassword}
            onInput={(e: any) => {
              setPassword(e.target.value);
            }}
            onBlur={() => passwordValidation()}
            helperText={validationModel?.passwordMessage}
            inputProps={InputFieldInputStyle(validationModel?.isValidPassword)}
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
            {t(keys.confirm_password)}
          </Label>
          <PasswordTextField
            placeholder={t(keys.enter_password)}
            error={!validationModel?.isValidConfirmPassword}
            onInput={(e: any) => {
              setConfirmPassword(e.target.value);
            }}
            onBlur={() => confirmPasswordValidation()}
            helperText={validationModel?.confirmPasswordMessage}
            inputProps={InputFieldInputStyle(
              validationModel?.isValidConfirmPassword,
            )}
          />
        </InputFieldWrapper>
        <PasswordValidator
          password={password}
          setPasswordStrengthValidated={setPasswordStrengthValidated}
        />
        <Button onClickHandler={onSignupButtonClicked} type="submit">
          {t(keys.sign_up)}
        </Button>
      </LoginWrapper>
    </BaseForm>
  );
};

const PURE = withLoader(Signup);
export default PURE;
