import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./interface";

export const CartListSlice = createSlice({
  name: "cartlist",
  initialState: initialState,
  reducers: {
    request: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    success: (state, action) => {
      state.isFetching = false;
      state.cartlist = action.payload;
      state.isError = false;
    },
    error: (state, action) => {
      state.isFetching = false;
      state.cartlist = [];
      state.isError = true;
    },
  },
});

export const { actions: CartListActions } = CartListSlice;

export default CartListSlice.reducer;
