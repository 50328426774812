import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { FlexiCardsActions } from ".";
import { getFlexiCardsApi } from "./api";

export default function* flexicards(): SagaIterator {
  try {
    const flexicardsResponse = yield call(getFlexiCardsApi);
    const { data } = flexicardsResponse;
    yield put(FlexiCardsActions.success(data?.response));
  } catch (e) {
    yield put(FlexiCardsActions.error(e?.response?.data?.message));
  }
}
