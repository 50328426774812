import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";

export const getFlexiCardsApi = () => {
  return axios.post(
    getBaseURL() + ENDPOINTS.MASTER_FLEXI_CARD,
    {},
    {
      headers: getHeaders(),
    },
  );
};
