import { BaseStateProps } from "data/interface";

export interface WalletDetailsResponse {
  message: string;
  response: WalletResponse;
}

export interface WalletDetailsStateProps extends BaseStateProps {
  walletdetails: WalletResponse;
}

export interface WalletResponse {
  id: number;
  earnings: number;
  redeemed: number;
  userId: number;
  createdAt: string;
  updatedAt: string;
  survaysCompleted: number;
}

export const initialState: WalletDetailsStateProps = {
  isFetching: false,
  walletdetails: {
    id: 0,
    earnings: 0,
    redeemed: 0,
    userId: 0,
    createdAt: "",
    updatedAt: "",
    survaysCompleted: 0,
  },
};

export interface AchievementTierDetailsResponse {
  message: string;
  response: AchievementTierDetails[];
}

export interface AchievementTierDetails {
  id: number;
  name: string;
  pointsMultiplier: number;
  minSurvays: number;
  maxSurvays: number;
}
