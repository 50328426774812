import { Divider } from "@mui/material";
import HeaderWithFilter from "components/headerwithfilter";
import { State } from "data/reducers/state";
import { GiftCardsActions } from "data/slices/giftcards";
import { GiftCardsStateProps } from "data/slices/giftcards/interface";
import keys from "locale/keys";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE } from "utils/constants";
import { getCoupons, searchCoupons } from "utils/mappers/CouponsMapper";
import PURE from "widgets/dashboard/redeempoints";
import { CouponProps } from "widgets/dashboard/redeempoints/interface";

import { RedeemProps } from "../interface";
import { Wrapper } from "../styled.components";

const RedeemPointsContainer = (props: RedeemProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [couponsToDisplay, setCouponsToDisplay] =
    useState<Map<string, CouponProps[]>>();

  const { giftcards, isFetching, isError, fetchedCards } = useSelector<
    State,
    GiftCardsStateProps
  >((state) => state.giftcards);

  useEffect(() => {
    if (!fetchedCards) {
      fetchGiftCardRetailers(DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGiftCardRetailers = (page: number, pageSize: number) => {
    dispatch(
      GiftCardsActions.request({
        pageSize,
        page,
      }),
    );
  };

  const onSearchCoupons = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  const groupedCoupons = useMemo(() => {
    return getCoupons(giftcards.rows);
  }, [giftcards.rows]);

  useEffect(() => {
    const coupons = searchCoupons(groupedCoupons, searchInput);
    setCouponsToDisplay(coupons);
  }, [groupedCoupons, searchInput]);

  return (
    <>
      <HeaderWithFilter
        title={t(keys.redeem_points)}
        showFilter={false}
        showBackButton={false}
        showSearchField={true}
        onSearchInput={onSearchCoupons}
      />
      <Divider />
      <Wrapper>
        <PURE
          coupons={couponsToDisplay}
          isError={isError}
          isFetching={isFetching} //fetching redeem points
        />
      </Wrapper>
    </>
  );
};

export default RedeemPointsContainer;
