import { Divider } from "@mui/material";
import HeaderWithFilter from "components/headerwithfilter";
import { TabItems } from "components/headerwithfilter/interface";
import keys from "locale/keys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getNotifications } from "utils/mappers/NotificationsMapper";
import Notifications from "widgets/dashboard/notifications";

import Survey from "../../../assets/icons/sidedrawer/tasks.svg";
import System from "../../../assets/icons/System.svg";
import Transactions from "../../../assets/icons/Transactions.svg";
import { Wrapper } from "../styled.components";

const NotificationsContainer = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChanged = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  const Tabs: TabItems[] = [
    {
      icon: Survey,
      title: t(keys.tab_survey),
    },
    {
      icon: System,
      title: t(keys.tab_system),
    },
    {
      icon: Transactions,
      title: t(keys.tab_transactions),
    },
  ];

  const notifications = getNotifications();
  var notificationsToDisplay;
  if (selectedTab === 0) {
    notificationsToDisplay = notifications.surveyNotifications;
  } else if (selectedTab === 1) {
    notificationsToDisplay = notifications.systemNotifications;
  } else {
    notificationsToDisplay = notifications.transactionNotifications;
  }

  return (
    <>
      <HeaderWithFilter
        title={t(keys.notifications)}
        showFilter={false}
        tabs={Tabs}
        selectedTab={selectedTab}
        onTabChanged={onTabChanged}
      />
      <Divider />
      <Wrapper>
        <Notifications notificationsToDisplay={notificationsToDisplay} />
      </Wrapper>
    </>
  );
};

export default NotificationsContainer;
