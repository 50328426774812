import { InputFieldInputStyle } from "commons/styled.components";
import Button from "components/button";
import Label from "components/label";
import Link from "components/link";
import TextField from "components/textfield";
import withLoader from "hoc/loader";
import keys from "locale/keys";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "utils/colors";

import { ValidationModel } from "../interface";
import {
  BaseForm,
  InputFieldWrapper,
  LabelWithLinkWrapper,
  LoginWrapper,
} from "../styled.components";
import { validateEmail, validateForgotPassword } from "../utils";
import { ForgotPasswordProps } from "./interface";

const ForgotPassword = (props: ForgotPasswordProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [validationModel, setValidationModel] = React.useState<ValidationModel>(
    {
      isValidUsername: true,
    },
  );

  const emailValidation = () => {
    const model = validateEmail(email);
    setValidationModel({
      ...validationModel,
      isValidUsername: model.isValid,
      userNameMessage: model.message,
    });
  };

  const onSendButtonClicked = (event: any) => {
    event.preventDefault();
    const model = validateForgotPassword(email);
    setValidationModel(model);
    if (model.isValidUsername) {
      props.onSendRecoveryMailHandler(email);
    }
  };

  if (props.isRecoveryMailSent) {
    return (
      <LoginWrapper>
        <Label type="large" color={COLORS.LOGIN_PRIMARY}>
          {t(keys.recovery_email_title)}
        </Label>
        <Label type="normalbold" color={COLORS.LOGIN_SECONDARY}>
          {t(keys.recovery_email_msg)}
        </Label>
        <LabelWithLinkWrapper>
          <Label type="medium" color={COLORS.LOGIN_SECONDARY}>
            {t(keys.recovery_email_redirection)}
          </Label>
          <Link onClickHandler={props.onLoginClickedHandler}>
            {t(keys.try_logging_in)}
          </Link>
        </LabelWithLinkWrapper>
      </LoginWrapper>
    );
  }

  return (
    <BaseForm onSubmit={onSendButtonClicked}>
      <LoginWrapper>
        <Label type="large" color={COLORS.LOGIN_PRIMARY}>
          {t(keys.lost_password_help)}
        </Label>
        <Label type="medium" color={COLORS.LOGIN_SECONDARY}>
          {t(keys.enter_email_associated)}
        </Label>
        <InputFieldWrapper>
          <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
            {t(keys.email)}
          </Label>
          <TextField
            error={!validationModel.isValidUsername}
            placeholder={t(keys.enter_email)}
            type="text"
            onInput={(e: any) => {
              setEmail(e.target.value);
            }}
            onBlur={() => emailValidation()}
            helperText={validationModel?.userNameMessage}
            inputProps={InputFieldInputStyle(validationModel?.isValidUsername)}
          />
        </InputFieldWrapper>
        <Button onClickHandler={onSendButtonClicked} type="submit">
          {t(keys.send)}
        </Button>
        <LabelWithLinkWrapper>
          <Label type="normalbold" color={COLORS.LOGIN_SECONDARY}>
            {t(keys.did_you_remember_password)}
          </Label>
          <Link onClick={props.onLoginClickedHandler}>
            {t(keys.try_logging_in)}
          </Link>
        </LabelWithLinkWrapper>
      </LoginWrapper>
    </BaseForm>
  );
};

const PURE = withLoader(ForgotPassword);
export default PURE;
