import { UserDetails } from "data/slices/userdetails/interface";
import i18n from "i18n";
import keys from "locale/keys";
import {
  PROFILE_SETTINGS_FIELD,
  PROFILE_SETTINGS_TYPE_DOB,
  PROFILE_SETTINGS_TYPE_ETHNICITY,
  PROFILE_SETTINGS_TYPE_GENDER,
  PROFILE_SETTINGS_TYPE_MARITAL,
  PROFILE_SETTINGS_TYPE_TEXT,
} from "utils/constants";
import { ProfileData } from "widgets/profilesettings/interface";

export const getProfileDetails = (details: UserDetails) => {
  const data: ProfileData[] = [
    {
      title: i18n.t(keys.first_name),
      type: PROFILE_SETTINGS_TYPE_TEXT,
      value: details.firstName,
      field: PROFILE_SETTINGS_FIELD.FIRST_NAME,
      isMandatory: true,
      isEditable: false,
    },
    {
      title: i18n.t(keys.last_name),
      type: PROFILE_SETTINGS_TYPE_TEXT,
      value: details.lastName,
      field: PROFILE_SETTINGS_FIELD.LAST_NAME,
      isMandatory: true,
      isEditable: false,
    },
    {
      title: i18n.t(keys.ethnicity),
      type: PROFILE_SETTINGS_TYPE_ETHNICITY,
      value: details.ethnicity,
      field: PROFILE_SETTINGS_FIELD.ETHNICITY,
      isMandatory: true,
    },
    {
      title: i18n.t(keys.postcode),
      type: PROFILE_SETTINGS_TYPE_TEXT,
      value: details.postCode,
      field: PROFILE_SETTINGS_FIELD.POSTCODE,
      isMandatory: true,
    },
    {
      title: i18n.t(keys.mobile_number),
      type: PROFILE_SETTINGS_TYPE_TEXT,
      value: details.mobile,
      field: PROFILE_SETTINGS_FIELD.MOBILE,
      isMandatory: false,
    },
    {
      title: i18n.t(keys.date_of_birth),
      type: PROFILE_SETTINGS_TYPE_DOB,
      value: details.dateOfBirth,
      field: PROFILE_SETTINGS_FIELD.DOB,
      isMandatory: true,
    },
    {
      title: i18n.t(keys.emailid),
      type: PROFILE_SETTINGS_TYPE_TEXT,
      value: details.email,
      isEditable: false,
      field: PROFILE_SETTINGS_FIELD.EMAIL,
      isMandatory: true,
    },
    /* {
      title: i18n.t(keys.address),
      type: PROFILE_SETTINGS_TYPE_TEXT,
      value: details.address,
      field: PROFILE_SETTINGS_FIELD.ADDRESS,
      isMandatory: false,
    }, */
    {
      title: i18n.t(keys.gender),
      type: PROFILE_SETTINGS_TYPE_GENDER,
      value: details.gender,
      field: PROFILE_SETTINGS_FIELD.GENDER,
      isMandatory: true,
    },
    {
      title: i18n.t(keys.marital_status),
      type: PROFILE_SETTINGS_TYPE_MARITAL,
      value: details.maritalStatus,
      field: PROFILE_SETTINGS_FIELD.MARITAL,
      isMandatory: false,
    },
  ];
  return data;
};

export const getProfileDataToSave = (details: UserDetails) => {
  return {
    firstName: details.firstName,
    lastName: details.lastName,
    ethnicity: details.ethnicity,
    postCode: details.postCode,
    mobile: details.mobile,
    dateOfBirth: details.dateOfBirth,
    email: details.email,
    //address: details.address,
    gender: details.gender,
    maritalStatus: details.maritalStatus,
  };
};
