import { Button } from "@mui/material";
import { useState } from "react";

import { QuantityButtonProps } from "./interface";
import { CountText, QuantityButtonGroup } from "./styled.components";

const CartQuantityButton = (props: QuantityButtonProps) => {
  const { initialCount, onChangeHandler, incrementlocal, disabled } = props;
  const [count, setCount] = useState(initialCount);

  const buttonStyle = {
    maxWidth: "32px",
    minWidth: "32px",
    border: "none",
    color: "white",
  };

  const onIncrement = (e: any) => {
    e.stopPropagation();
    if (incrementlocal) {
      setCount((count) => count + 1);
    }

    onChangeHandler(count + 1, true);
  };

  const onDecrement = (e: any) => {
    e.stopPropagation();
    if (incrementlocal) {
      setCount((count) => count - 1);
    }
    onChangeHandler(count - 1, false);
  };

  return (
    <QuantityButtonGroup
      disabled={disabled}
      variant="text"
      aria-label="Basic button group"
    >
      <Button style={buttonStyle} onClick={onDecrement}>
        -
      </Button>
      <CountText>{count}</CountText>
      <Button style={buttonStyle} onClick={onIncrement}>
        +
      </Button>
    </QuantityButtonGroup>
  );
};

export default CartQuantityButton;
