import styled from "@emotion/styled";
import { Button, css, Typography } from "@mui/material";
import { mobile } from "commons/styled.components";

export const CartWrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
  min-width: 750px;

  ${mobile(css`
    min-width: 0px;
  `)}
`;

export const CartItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${mobile(css`
    gap: 0.5rem;
  `)}
`;

export const CartItemWrapper = styled.div<{ instock: boolean }>`
  display: flex;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  opacity: ${(props) => (props.instock ? 1 : 0.3)};
  white-space: nowrap;
`;

export const TitleStack = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
`;

export const StockInfoWrapper = styled.div<{ instock?: boolean }>`
  display: inline-block;
  padding: 4px 6px;
  border-radius: 4px;
  background: ${(props) => (props.instock ? "#EDF2FE" : "#f7eded")};
`;

export const StockText = styled(Typography)<{ instock?: boolean }>`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => (props.instock ? "#4976F4" : "#AF4B4B")};
`;

export const TitleText = styled(Typography)`
  font-size: 1.12rem;
  font-weight: 400;
`;

export const ValueText = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0.5;
  flex-basis: 0;
`;

export const PointsText = styled(Typography)`
  font-size: 1.12rem;
  font-weight: 600;
  text-align: center;
  align-content: center;
  flex-grow: 0.5;
  flex-basis: 0;
`;

export const RemoveButton = styled(Button)`
  text-transform: none;
  color: black;
  font-size: 12px;
  font-weight: 400;
  flex-grow: 0.3;
  flex-basis: 0;
`;

export const SummaryBarWrapper = styled.div<{ bgcolor: string }>`
  display: flex;
  padding: 10px 16px;
  border-radius: 4px;
  margin: 16px 0px;
  background: ${(props) => props.bgcolor};

  ${mobile(css`
    padding: 10px;
  `)}
`;

export const SummaryBarTitle = styled(Typography)`
  flex-grow: 1.9;
  flex-basis: 0;
  font-size: 1.12rem;
  font-weight: 400;
`;

export const SummaryBarValue = styled(Typography)`
  font-size: 1.12rem;
  font-weight: 600;
  flex-grow: 0.5;
  flex-basis: 0;
  text-align: center;
  align-content: center;

  ${mobile(css`
    text-align: end;
  `)}
`;

export const DummyElement = styled.span`
  flex-grow: 0.3;
  flex-basis: 0;
`;

export const OptionsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  background: white;
  display: flex;
  margin-top: 48px;
  justify-content: space-between;
  padding: 24px 0px;
`;

export const CartContentWrapper = styled.div`
  white-space: nowrap;
  overflow-x: auto !important;
  width: 100% !important;

  &::-webkit-scrollbar-thumb {
    background: #909090;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export const RemoveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
`;
