//import { Edit } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "components/button";
import { BUTTON_SIZE } from "components/button/interface";
import ConfirmationDialog from "components/confirmationdialog";
import Label from "components/label";
import CustomLink from "components/link";
import dayjs, { Dayjs } from "dayjs";
import withLoader from "hoc/loader";
import keys from "locale/keys";
import { isEmpty, isEqual, isUndefined } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "utils/colors";
import {
  DOB_FORMAT,
  ETHNICITY,
  GENDERS,
  MARRIAGE_STATUS,
  PROFILE_SETTINGS_FIELD,
  PROFILE_SETTINGS_TYPE_DOB,
  PROFILE_SETTINGS_TYPE_ETHNICITY,
  PROFILE_SETTINGS_TYPE_GENDER,
  PROFILE_SETTINGS_TYPE_MARITAL,
  PROFILE_SETTINGS_TYPE_MOBILE,
  PROFILE_SETTINGS_TYPE_TEXT,
} from "utils/constants";
import { getDateFormat } from "utils/Utils";
import { v4 as uuidv4 } from "uuid";

import UserCircle from "../../assets/icons/UserCircle.svg";
import ChangePasswordDialog from "./ChangePasswordDialog";
import {
  ProfileData,
  ProfileSettingsProps,
  ValidationModel,
} from "./interface";
import {
  ButtonsWrapper,
  CustomTextField,
  InputFieldWrapper,
  LinksWrapper,
  ProfilePic,
  ProfilePicWrapper,
} from "./styled.components";
import { ValidateProfileDetails } from "./utils";

const ProfileSettings = (props: ProfileSettingsProps) => {
  const {
    updateProfile,
    data,
    onBackHandler,
    dataToSave,
    onSaveProfileDetails,
    onDeactivateAccount,
    onPasswordChangeHandler,
    profilePicUrl,
  } = props;
  const { t } = useTranslation();
  const [profileImage] = useState(profilePicUrl);
  const [profileImagefile] = useState("");
  const [validationModel, setValidationModel] = useState<ValidationModel>({
    isValidFirstName: true,
    isValidLastName: true,
    isValidEthnicity: true,
    isValidPostCode: true,
    isValidDOB: true,
    isValidGender: true,
  });

  var dateOfBirth = dayjs();
  data.forEach((item) => {
    if (item.type === 3) {
      dateOfBirth = dayjs(item.value, "DD MMM YYYY");
      return;
    }
  });

  const [dob, setDOB] = useState<Dayjs | null>(dateOfBirth);
  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);

  //this object will be directly used to save in api
  const [updatedProfileDetails, setUpdatedProfileDetails] =
    useState(dataToSave);

  const changeGender = (event: any) => {
    setUpdatedProfileDetails((props) => {
      return { ...props, gender: event.target.value };
    });
  };

  const changeEthnicity = (event: any) => {
    setUpdatedProfileDetails((props) => {
      return { ...props, ethnicity: event.target.value };
    });
  };

  const changeMaritalStatus = (event: any) => {
    setUpdatedProfileDetails((props) => {
      return { ...props, maritalStatus: event.target.value };
    });
  };

  const onTextChanged = (newText: string, field: number) => {
    if (isEqual(field, PROFILE_SETTINGS_FIELD.FIRST_NAME)) {
      setUpdatedProfileDetails((props) => {
        return { ...props, firstName: newText };
      });
    } else if (isEqual(field, PROFILE_SETTINGS_FIELD.LAST_NAME)) {
      setUpdatedProfileDetails((props) => {
        return { ...props, lastName: newText };
      });
    } else if (isEqual(field, PROFILE_SETTINGS_FIELD.POSTCODE)) {
      setUpdatedProfileDetails((props) => {
        return { ...props, postCode: newText };
      });
    } else if (isEqual(field, PROFILE_SETTINGS_FIELD.MOBILE)) {
      setUpdatedProfileDetails((props) => {
        return { ...props, mobile: newText };
      });
    } else if (isEqual(field, PROFILE_SETTINGS_FIELD.ADDRESS)) {
      setUpdatedProfileDetails((props) => {
        return { ...props, address: newText };
      });
    }
  };

  const updateDate = (newDate: Dayjs) => {
    setDOB(newDate);
    setUpdatedProfileDetails((props) => {
      return { ...props, dateOfBirth: getDateFormat(newDate) };
    });
  };

  const onUpdateDetailsClicked = () => {
    const model = ValidateProfileDetails(updatedProfileDetails);
    setValidationModel(model);
    if (
      model.isValidFirstName &&
      model.isValidLastName &&
      model.isValidPostCode &&
      model.isValidEthnicity &&
      model.isValidGender &&
      model.isValidDOB
    ) {
      onSaveProfileDetails(updatedProfileDetails, profileImagefile);
    }
  };

  const getErrorValidation = (field: number) => {
    if (isEqual(field, PROFILE_SETTINGS_FIELD.FIRST_NAME)) {
      return validationModel?.firstNameMessage;
    } else if (isEqual(field, PROFILE_SETTINGS_FIELD.LAST_NAME)) {
      return validationModel?.lastNameMessage;
    } else if (isEqual(field, PROFILE_SETTINGS_FIELD.ETHNICITY)) {
      return validationModel?.ethnicityMessage;
    } else if (isEqual(field, PROFILE_SETTINGS_FIELD.POSTCODE)) {
      return validationModel?.postCodeMessage;
    } else if (isEqual(field, PROFILE_SETTINGS_FIELD.GENDER)) {
      return validationModel?.genderMessage;
    } else if (isEqual(field, PROFILE_SETTINGS_FIELD.DOB)) {
      return validationModel?.DOBMessage;
    }
  };

  const getTextField = (profileData: ProfileData, type: number) => {
    let isReadonly = !updateProfile;
    if (updateProfile && !isUndefined(profileData.isEditable)) {
      isReadonly = true;
    }

    const validationMessage = getErrorValidation(profileData.field);
    const isValidField =
      isUndefined(validationMessage) || isEmpty(validationMessage);

    return (
      <CustomTextField
        error={!isValidField}
        helperText={validationMessage}
        type="text"
        defaultValue={profileData.value}
        InputProps={{
          readOnly: isReadonly,
        }}
        onInput={(event: any) => {
          onTextChanged(event.target.value, profileData.field);
        }}
        inputProps={{
          style: {
            padding: "10px",
            fontSize: "0.88rem",
            background: isReadonly ? "#F2F2F2" : "white",
            color: isReadonly
              ? "#00000061"
              : isValidField
                ? COLORS.INPUT_TEXT
                : COLORS.INPUT_TEXT_ERROR,
          },
        }}
      />
    );
  };

  const getDropdownTextField = (
    defaultValue: string,
    values: string[],
    type: number,
  ) => {
    var changeCallback;
    var validationMessage = "";
    if (isEqual(type, PROFILE_SETTINGS_TYPE_GENDER)) {
      changeCallback = changeGender;
      validationMessage = getErrorValidation(PROFILE_SETTINGS_FIELD.GENDER);
    } else if (isEqual(type, PROFILE_SETTINGS_TYPE_MARITAL)) {
      changeCallback = changeMaritalStatus;
    } else {
      changeCallback = changeEthnicity;
      validationMessage = getErrorValidation(PROFILE_SETTINGS_FIELD.ETHNICITY);
    }

    const isValidField =
      isUndefined(validationMessage) || isEmpty(validationMessage);

    return (
      <FormControl error={!isValidField}>
        <Select
          disabled={!updateProfile}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={defaultValue}
          onChange={changeCallback}
          sx={{
            "& .MuiSelect-select": {
              padding: "10px",
              background: !updateProfile ? "#F2F2F2" : "white",
              color: !updateProfile ? "#00000061" : "black",
            },
          }}
        >
          {values.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {!isValidField && <FormHelperText>{validationMessage}</FormHelperText>}
      </FormControl>
    );
  };

  const getDatePicker = () => {
    const validationMessage = getErrorValidation(PROFILE_SETTINGS_FIELD.DOB);
    const isValidField =
      isUndefined(validationMessage) || isEmpty(validationMessage);
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={!updateProfile}
          value={dob}
          onChange={(date: any) => {
            updateDate(date);
          }}
          format={DOB_FORMAT}
          maxDate={dayjs()}
          slotProps={{
            textField: {
              error: !isValidField,
              helperText: validationMessage,
            },
          }}
          sx={{
            "& div.MuiInputBase-root": {
              height: "43px",
              background: !updateProfile ? "#F2F2F2" : "white",
            },
          }}
        />
      </LocalizationProvider>
    );
  };

  const displayDeactivationDialog = () => {
    setShowDeactivateDialog(true);
  };

  const dismissDeactivationDialog = () => {
    setShowDeactivateDialog(false);
  };

  const displayChangePasswordDialog = () => {
    setShowChangePasswordDialog(true);
  };

  const dismissChangePasswordDialog = () => {
    setShowChangePasswordDialog(false);
  };

  /*const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setProfileImageFile(event.target.files[0]);
      setProfileImage(URL.createObjectURL(event.target.files[0]));
    }
  };*/

  //if not image, show placeholder
  const profilePic = profileImage ? profileImage : UserCircle;

  return (
    <>
      <ProfilePicWrapper>
        <ProfilePic
          alt="user profile pic"
          src={profilePic}
          loading="lazy"
          onError={(e) => {
            e.currentTarget.src = UserCircle;
          }}
        />
      </ProfilePicWrapper>
      <Grid container rowSpacing="24px" columnSpacing="36px">
        {data.map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
            <InputFieldWrapper>
              <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
                {item.title}&nbsp;
                {item.isMandatory ? (
                  <span style={{ color: "red" }}>*</span>
                ) : (
                  ""
                )}
              </Label>
              {(isEqual(item.type, PROFILE_SETTINGS_TYPE_TEXT) ||
                isEqual(item.type, PROFILE_SETTINGS_TYPE_MOBILE)) &&
                getTextField(item, item.type)}
              {isEqual(item.type, PROFILE_SETTINGS_TYPE_GENDER) &&
                getDropdownTextField(
                  updatedProfileDetails.gender,
                  GENDERS,
                  PROFILE_SETTINGS_TYPE_GENDER,
                )}
              {isEqual(item.type, PROFILE_SETTINGS_TYPE_MARITAL) &&
                getDropdownTextField(
                  updatedProfileDetails.maritalStatus,
                  MARRIAGE_STATUS,
                  PROFILE_SETTINGS_TYPE_MARITAL,
                )}
              {isEqual(item.type, PROFILE_SETTINGS_TYPE_DOB) && getDatePicker()}
              {isEqual(item.type, PROFILE_SETTINGS_TYPE_ETHNICITY) &&
                getDropdownTextField(
                  updatedProfileDetails.ethnicity,
                  ETHNICITY,
                  PROFILE_SETTINGS_TYPE_ETHNICITY,
                )}
            </InputFieldWrapper>
          </Grid>
        ))}
      </Grid>
      {!updateProfile && (
        <LinksWrapper>
          <CustomLink onClickHandler={displayChangePasswordDialog}>
            {t(keys.change_password)}
          </CustomLink>
          <CustomLink onClickHandler={displayDeactivationDialog}>
            {t(keys.deactivate_account)}
          </CustomLink>
        </LinksWrapper>
      )}
      {updateProfile && (
        <ButtonsWrapper>
          <Button
            variant="outlined"
            size={BUTTON_SIZE.MEDIUM}
            onClickHandler={onBackHandler}
          >
            {t(keys.back)}
          </Button>
          <Button
            variant="contained"
            size={BUTTON_SIZE.MEDIUM}
            onClickHandler={() => onUpdateDetailsClicked()}
          >
            {t(keys.update)}
          </Button>
        </ButtonsWrapper>
      )}
      <ConfirmationDialog
        show={showDeactivateDialog}
        dismiss={dismissDeactivationDialog}
        type={1}
        msg={t(keys.deactivate_dialog_msg)}
        buttonText={t(keys.confirm)}
        onButtonClickHandler={() => {
          onDeactivateAccount();
          dismissDeactivationDialog();
        }}
      />
      <ChangePasswordDialog
        key={uuidv4()}
        show={showChangePasswordDialog}
        dismiss={dismissChangePasswordDialog}
        onPasswordChangeHandler={onPasswordChangeHandler}
      />
    </>
  );
};

const PURE = withLoader(ProfileSettings);
export default PURE;
