import styled from "@emotion/styled";
import { Button, css, IconButton, Typography } from "@mui/material";
import { mobile } from "commons/styled.components";
import TextField from "components/textfield";
import { COLORS } from "utils/colors";

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

export const SectionTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  flex-grow: 0.2;
  flex-basis: 0;
  padding: 0.5rem 0;
`;

export const SectionBody = styled.div`
  flex-grow: 0.8;
  flex-basis: 0;
`;

export const InviteRowWrapper = styled.div`
  display: flex;
  width; 100%;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const InviteDetailsTextField = styled(TextField)`
  flex-grow: 0.8;
  flex-basis: 0;
`;

export const SendWrapper = styled.div`
  display: flex;
  align-content: start;
  justify-content: center;
  height: 100%;
  flex-wrap: wrap;

  ${mobile(css`
    margin-top: 0.5rem;
    justify-content: start;
  `)}
`;

export const AddRemoveIconButton = styled(IconButton)`
  margin-top: 4px;
  width: 2rem;
  height: 2rem;
`;

export const SendButton = styled(Button)`
  border-color: ${COLORS.BRAND_DEFAULT_COLOR};
  color: black;

  :hover {
    border-color: black;
  }
`;
