import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";
import { ProfileDataToSave } from "widgets/profilesettings/interface";

import { UpdateUserDetailsRequest } from "./interface";

export const updateUserDetailsApi = async (
  payload: UpdateUserDetailsRequest,
) => {
  try {
    const { data } = await axios.patch(
      getBaseURL() + ENDPOINTS.UPDATE_USER_DETAILS,
      payload,
      {
        headers: getHeaders(),
      },
    );

    return data;
  } catch (e) {
    return e.response?.data;
  }
};

export const updateProfileSettingDetailsApi = async (
  payload: ProfileDataToSave,
) => {
  try {
    const { data } = await axios.patch(
      getBaseURL() + ENDPOINTS.UPDATE_USER_DETAILS,
      payload,
      {
        headers: getHeaders(),
      },
    );

    return data;
  } catch (e) {
    return e.response?.data;
  }
};
