import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import withLoader from "hoc/loader";

import { MySurveyProps } from "./interface";
import SurveySet from "./SurveySet";

const MySurveys = (props: MySurveyProps) => {
  const {
    newSurveys,
    completedSurveys,
    onViewMoreClicked,
    isNewSurveysLoading,
    isCompletedSurveysLoading,
    onStartSurveyClicked,
    isError,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <SurveySet
        {...newSurveys}
        onViewMoreClicked={onViewMoreClicked}
        isLoading={isNewSurveysLoading}
        onStartSurveyClicked={onStartSurveyClicked}
        isError={isError}
        isMobile={isMobile}
      />
      <SurveySet
        {...completedSurveys}
        setTopMargin={true}
        onViewMoreClicked={onViewMoreClicked}
        isLoading={isCompletedSurveysLoading}
        isError={isError}
        isMobile={isMobile}
      />
    </>
  );
};

const SURVEYSPURE = withLoader(MySurveys);
export default SURVEYSPURE;
