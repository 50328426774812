import { InputFieldInputStyle } from "commons/styled.components";
import Button from "components/button";
import Label from "components/label";
import Link from "components/link";
import TextField from "components/textfield";
import PasswordTextField from "components/textfield/PasswordTextField";
import withLoader from "hoc/loader";
import keys from "locale/keys";
import { useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "utils/colors";

import { ValidationModel } from "../interface";
import {
  BaseForm,
  InputFieldWrapper,
  LinkWrapper,
  LoginWrapper,
} from "../styled.components";
import { validateEmail, validateLogin, validatePassword } from "../utils";
import { LoginProps } from "./interface";

const Login = (props: LoginProps) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validationModel, setValidationModel] = useState<ValidationModel>({
    isValidPassword: true,
    isValidUsername: true,
  });

  //TODO: instead of individual and onsubmit validation, can be combined to one?
  const emailValidation = () => {
    const model = validateEmail(username);
    setValidationModel({
      ...validationModel,
      isValidUsername: model.isValid,
      userNameMessage: model.message,
    });
  };

  const passwordValidation = () => {
    const model = validatePassword(password);
    setValidationModel({
      ...validationModel,
      isValidPassword: model.isValid,
      passwordMessage: model.message,
    });
  };

  const onSubmitLoginHandler = (event: any) => {
    event.preventDefault();
    const model = validateLogin(username, password);
    setValidationModel(model);
    if (model.isValidPassword && model.isValidUsername) {
      props.onLoginHandler(username, password);
    }
  };

  return (
    <BaseForm onSubmit={onSubmitLoginHandler}>
      <LoginWrapper>
        <Label type="large" color={COLORS.LOGIN_PRIMARY}>
          {t(keys.login)}
        </Label>
        <Label type="medium" color={COLORS.LOGIN_SECONDARY}>
          {t(keys.please_enter_details)}
        </Label>
        <InputFieldWrapper>
          <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
            {t(keys.email)}
          </Label>
          <TextField
            error={!validationModel?.isValidUsername}
            placeholder={t(keys.enter_email)}
            type="email"
            onInput={(e: any) => {
              setUsername(e.target.value);
            }}
            onBlur={() => emailValidation()}
            helperText={validationModel?.userNameMessage}
            inputProps={InputFieldInputStyle(validationModel?.isValidUsername)}
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
            {t(keys.password)}
          </Label>
          <PasswordTextField
            placeholder={t(keys.password)}
            error={!validationModel?.isValidPassword}
            onInput={(e: any) => {
              setPassword(e.target.value);
            }}
            onBlur={() => passwordValidation()}
            helperText={validationModel?.passwordMessage}
            inputProps={InputFieldInputStyle(validationModel?.isValidPassword)}
          />
        </InputFieldWrapper>
        <LinkWrapper>
          <Link onClick={props.onForgotPasswordHandler}>
            {t(keys.forgot_password)}
          </Link>
        </LinkWrapper>
        <Button onClickHandler={onSubmitLoginHandler} type="submit">
          {t(keys.login)}
        </Button>
        {/* <LabelWithLinkWrapper>
          <Label type="normalbold" color={COLORS.LOGIN_SECONDARY}>
            {t(keys.dont_have_account)}
          </Label>
          <Link onClick={props.onSignupHandler}>{t(keys.sign_up)}</Link>
        </LabelWithLinkWrapper> */}
      </LoginWrapper>
    </BaseForm>
  );
};

const PURE = withLoader(Login);
export default PURE;
