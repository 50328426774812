import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import keys from "locale/keys";
import { useTranslation } from "react-i18next";

import AppLogo from "../../assets/icons/ApplogoBlack.svg";
import { AppLogoImg, Message, Wrapper } from "./styled.components";

const SurveyFailureRedirection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <AppLogoImg src={AppLogo} alt="app logo" />
      <CancelOutlinedIcon
        style={{ color: "red", width: "4rem", height: "4rem" }}
      />
      <Message>{t(keys.survey_failure_msg)}</Message>
    </Wrapper>
  );
};

export default SurveyFailureRedirection;
