import { isEqual } from "lodash";
import { COLORS } from "utils/colors";

import { BUTTON_SIZE } from "./interface";
import { CustomButton } from "./styled.components";

//TODO interface for props
const Button = (props: any) => {
  const marginTop = props.margintop ?? "0px";
  const variant = props.variant ?? "contained";
  const buttonSize = props.size ?? BUTTON_SIZE.LARGE;
  const bgColor = isEqual(variant, "contained")
    ? COLORS.BRAND_DEFAULT_COLOR
    : "white";
  const color = isEqual(variant, "contained") ? "white" : "black";
  const type = props.type ?? undefined;

  return (
    <CustomButton
      disabled={props.disabled}
      variant={variant}
      margintop={marginTop}
      bgcolor={bgColor}
      textcolor={color}
      onClick={props.onClickHandler}
      buttonsize={buttonSize}
      type={type}
    >
      {props.children}
    </CustomButton>
  );
};

export default Button;
