import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";

import { SurveyListRequest } from "./interface";

export const getSurveyListApi = (payload: SurveyListRequest) => {
  const url = `${ENDPOINTS.USER_SURVEY}?status=${payload.status}&page_size=${payload.pageSize}&page=${payload.page}`;
  return axios.get(getBaseURL() + url, {
    headers: getHeaders(),
  });
};

//TODO: can be combined with above method. For now, instead of saving to redux directly using the response
export const getSurveyListPaginationApi = async (
  payload: SurveyListRequest,
) => {
  try {
    const url = `${ENDPOINTS.USER_SURVEY}?status=${payload.status}&page_size=${payload.pageSize}&page=${payload.page}`;
    const { data } = await axios.get(getBaseURL() + url, {
      headers: getHeaders(),
    });

    return data;
  } catch (e) {
    return {
      message: e.response?.data?.message,
      response: { count: 0, rows: [] },
    }; //manually added error for erro snackbar validation
  }
};

export const surveyIncreaseClicks = async (id: number) => {
  try {
    const url = `${ENDPOINTS.INCREASE_CLICKS}${id}`;
    const { data } = await axios.get(getBaseURL() + url, {
      headers: getHeaders(),
    });

    return data;
  } catch (e) {
    return {
      message: e.response?.data?.message,
    };
  }
};
