import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";

import { UserInvitationRequest } from "./interface";

export const sendUserInvitation = async (request: UserInvitationRequest) => {
  try {
    const { data } = await axios.post(
      getBaseURL() + ENDPOINTS.INVITATION_SINGLE,
      request,
    );

    return data;
  } catch (e) {
    return { message: e.response?.data?.message, error: {} }; //manually added error for error snackbar validation
  }
};

export const getInviteesList = async (userId: number) => {
  try {
    const url = `${ENDPOINTS.INVITATION_LIST}?invitedBy=${userId}`;
    const { data } = await axios.get(getBaseURL() + url, {
      headers: getHeaders(),
    });

    return data;
  } catch (e) {
    return { message: e.response?.data?.message, error: {} }; //manually added error for error snackbar validation
  }
};
