import { DASHBOARD_ROUTES, ROUTES } from "utils/constants";

import Logout from "../../assets/icons/Logout.svg";
import menu_shopping_cart from "../../assets/icons/menu_shopping_cart.svg";
import Inviteusers from "../../assets/icons/sidedrawer/invite_user.svg";
import Orders from "../../assets/icons/sidedrawer/Orders.svg";
import Redeem from "../../assets/icons/sidedrawer/redeem.svg";
import Support from "../../assets/icons/sidedrawer/Support.svg";
import Tasks from "../../assets/icons/sidedrawer/tasks.svg";
import Wallet from "../../assets/icons/sidedrawer/wallet.svg";
import User from "../../assets/icons/User.svg";

export const SIDEDRAWER_ITEMS = [
  {
    title: "Surveys",
    icon: Tasks,
    route: DASHBOARD_ROUTES.TASKS,
    enabled: true,
  },
  {
    title: "My Wallet	",
    icon: Wallet,
    route: DASHBOARD_ROUTES.MY_WALLET,
    enabled: true,
  },
  {
    title: "Redeem Points",
    icon: Redeem,
    route: DASHBOARD_ROUTES.REDEEM,
    enabled: true,
  },
  {
    title: "My Orders",
    icon: Orders,
    route: DASHBOARD_ROUTES.MY_ORDERS,
    enabled: true,
  },
  {
    title: "Refer Users",
    icon: Inviteusers,
    route: DASHBOARD_ROUTES.REFER_USERS,
    enabled: true,
  },
  /* {
    title: "Notifications",
    icon: Notifications,
    route: DASHBOARD_ROUTES.NOTIFICATIONS,
    enabled: false,
  }, */
];

export const SIDEDRAWER_FOOTER_ITEMS = [
  {
    title: "Support",
    icon: Support,
    route: DASHBOARD_ROUTES.SUPPORT,
    enabled: true,
  },
];

export const MENU_ITEMS = [
  ...SIDEDRAWER_ITEMS,
  {
    title: "Cart",
    icon: menu_shopping_cart,
    route: DASHBOARD_ROUTES.CART,
    enabled: true,
  },
  {
    title: "Profile Settings",
    icon: User,
    route: DASHBOARD_ROUTES.PROFILE_SETTINGS,
    enabled: true,
  },
  {
    title: "Log out",
    icon: Logout,
    route: ROUTES.LOGIN,
    enabled: true,
  },
];
