import styled from "@emotion/styled";
import { css, Stack, Typography } from "@mui/material";
import { mobile } from "commons/styled.components";
import { COLORS } from "utils/colors";

export const GenericTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 750px;

  ${mobile(css`
    min-width: 0px;
  `)}
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  background: ${COLORS.BRAND_LIGHT_BACKGROUND};
  border: none;
  color: rgb(0, 0, 0);
`;

export const HeaderItem = styled(Typography)`
  flex-basis: 0;
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 400;
`;

export const TableItemWrapper = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
`;

export const TableItemText = styled(Typography)<{ isbold?: boolean }>`
  flex-basis: 0;
  flex-grow: 1;
  font-size: 0.88rem;
  font-weight: ${(props) => (props.isbold ? 500 : 400)};
  color: #000000;
  text-wrap: wrap;
  min-width: 0;
`;

export const StackItem = styled(Stack)`
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  flex-wrap: wrap;
  min-width: 0;
  overflow-wrap: anywhere;
`;

export const ItemWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`;

export const PointsItem = styled.span`
  border-radius: 4px;
  background: #f7f7e8;
  font-size: 0.88rem;
  font-weight: 600;
  color: #ff9f02;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
`;

export const TableWrapper = styled.div`
  white-space: nowrap;
  overflow-x: auto !important;
  width: 100% !important;

  &::-webkit-scrollbar-thumb {
    background: #909090;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export const RetailerImageWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 0.6;
`;

export const MobileItemDescription = styled.th`
  font-size: 12px;
  font-weight: normal;
`;

export const MobileItemValue = styled.td`
  font-size: 14px;
  padding-left: 8px;
`;

export const RetailerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
