import CustomDivider from "components/divider";
import LoadingEmptyError from "components/LoaderEmptyErrorState";
import GenericTable from "components/table/GenericTable";
import withLoader from "hoc/loader";
import keys from "locale/keys";
import { useTranslation } from "react-i18next";

import EmailInvites from "./EmailInvites";
import { ReferalsProps } from "./interface";
import { SectionTitle, SectionWrapper } from "./styled.components";

const Referals = (props: ReferalsProps) => {
  const { referalsList, sendUserInvite, fetchingReferalsList, resetInput } =
    props;
  const { t } = useTranslation();

  const showReferalsList = () => {
    const isEmpty = referalsList && referalsList?.tableItems?.length === 0;
    if (fetchingReferalsList || false || isEmpty) {
      return (
        <SectionWrapper style={{ marginTop: "1rem", flexDirection: "column" }}>
          <SectionTitle>{t(keys.referals)}</SectionTitle>
          <LoadingEmptyError
            isEmpty={isEmpty}
            isLoading={fetchingReferalsList}
            isError={false}
          />
        </SectionWrapper>
      );
    }

    return (
      <SectionWrapper style={{ marginTop: "1rem", flexDirection: "column" }}>
        <SectionTitle>{t(keys.referals)}</SectionTitle>
        <GenericTable {...referalsList} />
      </SectionWrapper>
    );
  };

  return (
    <>
      <EmailInvites sendUserInvite={sendUserInvite} resetInput={resetInput} />
      <CustomDivider />
      {showReferalsList()}
    </>
  );
};

export default withLoader(Referals);
