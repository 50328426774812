import { Radio } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "components/button";
import { BUTTON_SIZE } from "components/button/interface";
import Label from "components/label";
import dayjs, { Dayjs } from "dayjs";
import keys from "locale/keys";
import { isEmpty, isUndefined } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DOB_FORMAT, GENDERS, MARRIAGE_STATUS } from "utils/constants";
import { getDateFormat } from "utils/Utils";
import { v4 as uuidv4 } from "uuid";

import birthDay_image from "../../assets/icons/birthDay_image.gif";
import GenderFemale from "../../assets/icons/GenderFemale.svg";
import GenderMale from "../../assets/icons/GenderMale.svg";
import GenderNonbinary from "../../assets/icons/GenderNonbinary.svg";
import location_image from "../../assets/icons/location_image.gif";
import relationship from "../../assets/icons/relationship.svg";
import { WELCOME_STEPS_COUNT } from "./Constants";
import { QuestionnaireProps, ValidationModel } from "./interface";
import {
  AddressField,
  AddressImage,
  AddressImageWrapper,
  DateInputWrapper,
  GenderImages,
  GroupWrapper,
  ImageContainer,
  ImageWrapper,
  NavigationButtonsWrapper,
  OutlinedFormControl,
  QuestionnaireTitle,
  QuestionnaireWrapper,
  RadioGroupWrapper,
  RotatingImage,
  ToDashboardLink,
  ToDashboardWrapper,
  WelcomeMessage,
  WelcomeTitle,
  WelcomeWrapper,
} from "./styled.components";
import { ValidateWelcomeProfileDetails } from "./utils";

const Questionnaire = (props: QuestionnaireProps) => {
  const { step, navigateToDashboard, onNextHandler, onBackHandler } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState<Dayjs | null>(null);
  const [genderValue, setGenderValue] = useState("");
  const [address, setAddress] = useState("");
  const [marriageStatus, setMarriageStatus] = useState("");
  const [enableButton, setEnableButton] = useState(false);
  const [validationModel, setValidationModel] = useState<ValidationModel>({
    isValidDOB: false,
  });

  useEffect(() => {
    if (
      (step === 1 && validationModel.isValidDOB) ||
      (step === 2 && genderValue) ||
      (step === 3 && address) ||
      (step === 4 && marriageStatus)
    ) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [step, validationModel, genderValue, address, marriageStatus]);

  const onNext = () => {
    //dont disable for last step
    if (step !== WELCOME_STEPS_COUNT) {
      setEnableButton(false);
    }
    onNextHandler({
      dob: value,
      gender: genderValue,
      address,
      martialStatus: marriageStatus,
    });
  };

  const onBack = () => {
    setEnableButton(true);
    onBackHandler();
  };

  const onUpdateDate = (newDate: Dayjs) => {
    setValue(newDate);
    const model = ValidateWelcomeProfileDetails(getDateFormat(newDate));
    setValidationModel(model);
  };

  const handleGenderChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGenderValue((event.target as HTMLInputElement).value);
  };

  const handleMarriageStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMarriageStatus((event.target as HTMLInputElement).value);
  };

  const step0 = () => {
    return (
      <WelcomeWrapper data-testid="welcome-step0-wrapper">
        <WelcomeTitle>{t(keys.welcome_screen_title)}</WelcomeTitle>
        <WelcomeMessage>{t(keys.welcome_screen_message)}</WelcomeMessage>

        <Button
          variant="outlined"
          onClickHandler={props.onNextHandler}
          size={BUTTON_SIZE.MEDIUM}
        >
          {t(keys.get_started)}
        </Button>

        <ToDashboardWrapper>
          <Label type={"medium"}>{t(keys.skip)}</Label>
          <ToDashboardLink onClickHandler={navigateToDashboard}>
            {t(keys.take_me_to_homepage)}
          </ToDashboardLink>
        </ToDashboardWrapper>
      </WelcomeWrapper>
    );
  };

  const step1 = () => {
    const validationMessage = validationModel.DOBMessage;
    const isValidField =
      isUndefined(validationMessage) || isEmpty(validationMessage);

    return (
      <GroupWrapper data-testid="welcome-step-wrapper">
        <ImageWrapper src={birthDay_image} />
        <QuestionnaireTitle>
          {t(keys.questionnaire_one_title)}
        </QuestionnaireTitle>
        <DateInputWrapper>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={value}
              onChange={(date: any) => {
                onUpdateDate(date);
              }}
              maxDate={dayjs()}
              format={DOB_FORMAT}
              slotProps={{
                textField: {
                  error: !isValidField,
                  helperText: validationMessage,
                },
              }}
            />
          </LocalizationProvider>
        </DateInputWrapper>
      </GroupWrapper>
    );
  };

  const step2 = () => {
    return (
      <GroupWrapper data-testid="welcome-step-wrapper">
        <GenderImages>
          <RotatingImage src={GenderFemale} />
          <ImageContainer>
            <RotatingImage src={GenderMale} />
          </ImageContainer>
          <RotatingImage src={GenderNonbinary} />
        </GenderImages>
        <QuestionnaireTitle>
          {t(keys.questionnaire_two_title)}
        </QuestionnaireTitle>
        <RadioGroupWrapper
          className="gender"
          row
          name="row-radio-buttons-group"
          value={genderValue}
          onChange={handleGenderChange}
        >
          {GENDERS.map((s) => {
            return (
              <OutlinedFormControl
                style={{ margin: "0", height: "2.81rem" }}
                key={uuidv4()}
                value={s}
                control={<Radio />}
                label={<Label type="normalbold">{s}</Label>}
              />
            );
          })}
        </RadioGroupWrapper>
      </GroupWrapper>
    );
  };

  const step3 = () => {
    return (
      <GroupWrapper data-testid="welcome-step-wrapper">
        <ImageWrapper src={location_image} />
        <QuestionnaireTitle>
          {t(keys.questionnaire_three_title)}
        </QuestionnaireTitle>
        <AddressField
          style={{ height: "2.81rem" }}
          placeholder={t(keys.enter_postcode)}
          value={address}
          onInput={(e: any) => {
            setAddress(e.target.value);
          }}
        />
      </GroupWrapper>
    );
  };

  const step4 = () => {
    return (
      <GroupWrapper
        data-testid="welcome-step-wrapper"
        className="maritalStatus"
      >
        <AddressImageWrapper>
          <AddressImage src={relationship} className="img1" />
          <AddressImage src={relationship} className="img2" />
        </AddressImageWrapper>
        <QuestionnaireTitle>
          {t(keys.questionnaire_four_title)}
        </QuestionnaireTitle>
        <RadioGroupWrapper
          row
          name="row-radio-buttons-group"
          value={marriageStatus}
          onChange={handleMarriageStatusChange}
          className="relationship"
        >
          {MARRIAGE_STATUS.map((s) => {
            return (
              <OutlinedFormControl
                key={uuidv4()}
                value={s}
                control={<Radio />}
                label={<Label type="normalbold">{s}</Label>}
                style={{ height: "2.81rem" }}
              />
            );
          })}
        </RadioGroupWrapper>
      </GroupWrapper>
    );
  };

  return (
    <QuestionnaireWrapper data-testid="welcome-questionnaire-wrapper">
      {step === 0 && step0()}
      {step === 1 && step1()}
      {step === 2 && step2()}
      {step === 3 && step3()}
      {step === 4 && step4()}
      {step !== 0 && (
        <NavigationButtonsWrapper data-testid="welcome-navigation-wrapper">
          <Button
            variant="outlined"
            onClickHandler={onBack}
            size={BUTTON_SIZE.MEDIUM}
          >
            {t(keys.back)}
          </Button>
          <Button
            disabled={!enableButton}
            variant="contained"
            onClickHandler={onNext}
            size={BUTTON_SIZE.MEDIUM}
          >
            {step === WELCOME_STEPS_COUNT
              ? t(keys.start_making_money)
              : t(keys.next)}
          </Button>
        </NavigationButtonsWrapper>
      )}
    </QuestionnaireWrapper>
  );
};

export default Questionnaire;
