import * as React from "react";

import { PasswordValidatorProps } from "./interface";
import {
  PrimaryLabel,
  SecondaryLabel,
  ValidationWrapper,
  Wrapper,
} from "./styled.components";
import { Validators } from "./utils";

const PasswordValidator = (props: PasswordValidatorProps) => {
  const { password, setPasswordStrengthValidated } = props;

  // TODO: iterating is not working for regex, so added elements manually. need to fix
  const isLengthMatched = /(?=.{8,})\w+/g.test(password);
  const hasUpperChar = /(?=.*[A-Z])\w+/g.test(password);
  const hasLowerChar = /(?=.*[a-z])\w+/g.test(password);
  const hasNumber = /(?=.*[0-9])\w+/g.test(password);
  const hasSymbol = /(?=.*[!@#$%^&*])\w+/g.test(password);

  React.useEffect(() => {
    setPasswordStrengthValidated(
      isLengthMatched && hasUpperChar && hasLowerChar && hasNumber && hasSymbol,
    );
  });

  return (
    <Wrapper>
      {/* {Validators.map((v: any) => {
        return (
          <ValidationWrapper key={uuidv4()}>
            <PrimaryLabel>{v.title}</PrimaryLabel>
            <SecondaryLabel>{v.description}</SecondaryLabel>
          </ValidationWrapper>
        );
      })} */}
      <ValidationWrapper isMatched={isLengthMatched}>
        <PrimaryLabel>{Validators[0].title}</PrimaryLabel>
        <SecondaryLabel>{Validators[0].description}</SecondaryLabel>
      </ValidationWrapper>
      <ValidationWrapper isMatched={hasUpperChar}>
        <PrimaryLabel>{Validators[1].title}</PrimaryLabel>
        <SecondaryLabel>{Validators[1].description}</SecondaryLabel>
      </ValidationWrapper>
      <ValidationWrapper isMatched={hasLowerChar}>
        <PrimaryLabel>{Validators[2].title}</PrimaryLabel>
        <SecondaryLabel>{Validators[2].description}</SecondaryLabel>
      </ValidationWrapper>
      <ValidationWrapper isMatched={hasNumber}>
        <PrimaryLabel>{Validators[3].title}</PrimaryLabel>
        <SecondaryLabel>{Validators[3].description}</SecondaryLabel>
      </ValidationWrapper>
      <ValidationWrapper isMatched={hasSymbol}>
        <PrimaryLabel>{Validators[4].title}</PrimaryLabel>
        <SecondaryLabel>{Validators[4].description}</SecondaryLabel>
      </ValidationWrapper>
    </Wrapper>
  );
};

export default PasswordValidator;
