import { CartCountActions } from "data/slices/cartcount";
import { UserDetailsActions } from "data/slices/userdetails";
import { WalletDetailsActions } from "data/slices/wallet";
import MiniDrawer from "hoc/sidedrawer";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  DASHBOARD_ROUTES,
  MENU_ITEM_LOGOUT,
  MENU_ITEM_PROFILE_SETTINGS,
  ROUTES,
  STORAGE_SELECTED_MENU,
} from "utils/constants";
import { getFromLocalStorage, logout } from "utils/Utils";

const DashboardContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSidebarItemSelected = (selectedRoute: string) => {
    navigate(selectedRoute, {
      replace: true,
    });
  };

  const onMenuItemSelected = (menuItem: number) => {
    if (isEqual(menuItem, MENU_ITEM_LOGOUT)) {
      logout();
      dispatch(UserDetailsActions.reset());
      dispatch(WalletDetailsActions.reset());
      dispatch(CartCountActions.reset());
      navigate(ROUTES.LOGIN, {
        replace: true,
      });
    } else if (isEqual(menuItem, MENU_ITEM_PROFILE_SETTINGS)) {
      navigate(DASHBOARD_ROUTES.PROFILE_SETTINGS);
    }
  };

  useEffect(() => {
    dispatch(UserDetailsActions.request());
    dispatch(CartCountActions.request());
    dispatch(WalletDetailsActions.request());
  }, [dispatch]);

  //get selected menu from local storage, as after refresh 0th element is selected by default
  var selectedMenu = 0;
  if (getFromLocalStorage(STORAGE_SELECTED_MENU)) {
    selectedMenu = Number(getFromLocalStorage(STORAGE_SELECTED_MENU));
  }

  return (
    <>
      <MiniDrawer
        onSidebarItemSelected={onSidebarItemSelected}
        onMenuItemSelected={onMenuItemSelected}
        selectedMenu={selectedMenu}
      />
    </>
  );
};

export default DashboardContainer;
