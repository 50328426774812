import { Grid, MenuItem, Select } from "@mui/material";
import { TableItemProps } from "commons/interface";
import Button from "components/button";
import { BUTTON_SIZE } from "components/button/interface";
import LoadingEmptyError from "components/LoaderEmptyErrorState";
import GenericTable from "components/table/GenericTable";
import TextField from "components/textfield";
import withLoader from "hoc/loader";
import keys from "locale/keys";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "utils/colors";
import {
  SUPPORT_CATEGORIES,
  SUPPORT_DESCRIPTION_MAX_LEGTH,
} from "utils/constants";
import { v4 as uuidv4 } from "uuid";
import { InputFieldWrapper } from "widgets/prelogin/styled.components";

import { SupportProps } from "./interface";
import {
  FieldTitle,
  IssuesTitle,
  SubmitButtonWrapper,
  SupportListWrapper,
} from "./styled.components";
import SupportDialog from "./TicketDetails";

const Support = (props: SupportProps) => {
  const {
    supportTickets,
    isLoadingTickets,
    isError,
    createSupportTicket,
    onUpdateTicketStatus,
  } = props;
  const { t } = useTranslation();
  const [category, setCategory] = useState(SUPPORT_CATEGORIES[0]);
  const [description, setDescription] = useState();
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [showTicketDetailsDialog, setShowTicketDetailsDialog] = useState(false);
  const [selectedTicketDetails, setSelectedTicketDetails] =
    useState<TableItemProps>({});

  const onViewButtonClicked = (item: TableItemProps) => {
    setShowTicketDetailsDialog(true);
    setSelectedTicketDetails(item);
  };

  const onDismissSupportDialog = () => {
    setShowTicketDetailsDialog(false);
  };

  const onCreateSupportTicketHandler = () => {
    if (isEmpty(description)) {
      setIsDescriptionValid(false);
    } else {
      setIsDescriptionValid(true);
      createSupportTicket(category, description);
    }
  };

  const showSupportTickets = () => {
    const isEmpty = supportTickets?.tableItems?.length === 0;
    if (isLoadingTickets || isEmpty || isError) {
      return (
        <LoadingEmptyError
          isLoading={isLoadingTickets}
          isEmpty={isEmpty}
          isError={isError}
        />
      );
    }

    return (
      <GenericTable
        {...supportTickets}
        onActionButtonClicked={onViewButtonClicked}
      />
    );
  };

  return (
    <>
      <Grid container rowSpacing="24px" columnSpacing="36px">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputFieldWrapper>
            <FieldTitle>{t(keys.category)}</FieldTitle>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              onChange={(event: any) => setCategory(event.target.value)}
              sx={{
                "& .MuiSelect-select": {
                  padding: "10px",
                  fontSize: "0.88rem",
                },
              }}
            >
              {SUPPORT_CATEGORIES.map((option: string, index: number) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </InputFieldWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <>
            <InputFieldWrapper>
              <FieldTitle>{t(keys.description)}</FieldTitle>
              <TextField
                error={!isDescriptionValid}
                helperText={
                  isDescriptionValid ? "" : t(keys.please_enter_description)
                }
                type="text"
                placeholder={t(keys.support_description)}
                onInput={(event: any) => setDescription(event.target.value)}
                multiline
                rows={4}
                inputProps={{
                  style: {
                    maxLength: SUPPORT_DESCRIPTION_MAX_LEGTH,
                    fontSize: "0.88rem",
                    color: isDescriptionValid
                      ? COLORS.INPUT_TEXT
                      : COLORS.INPUT_TEXT_ERROR,
                  },
                }}
              />
            </InputFieldWrapper>
            <SubmitButtonWrapper>
              <Button
                variant="contained"
                size={BUTTON_SIZE.MEDIUM}
                onClickHandler={() => onCreateSupportTicketHandler()}
              >
                {t(keys.submit)}
              </Button>
            </SubmitButtonWrapper>
          </>
        </Grid>
      </Grid>
      <SupportListWrapper>
        <IssuesTitle>{t(keys.issues_list)}</IssuesTitle>
        {showSupportTickets()}
      </SupportListWrapper>
      <SupportDialog
        key={uuidv4()}
        showDialog={showTicketDetailsDialog}
        onDismissDialog={onDismissSupportDialog}
        ticketDetails={selectedTicketDetails}
        onUpdateTicketStatus={onUpdateTicketStatus}
      />
    </>
  );
};

const PURE = withLoader(Support);
export default PURE;
