import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CoinsHistoryRequest, initialState } from "./interface";

export const CoinsHistorySlice = createSlice({
  name: "coinshistory",
  initialState: initialState,
  reducers: {
    request: (state, action: PayloadAction<CoinsHistoryRequest>) => {
      state.isFetching = true;
      state.isError = false;
    },
    success: (state, action) => {
      state.isFetching = false;
      state.coinshistory = action.payload;
      state.isError = false;
    },
    error: (state, action) => {
      state.isFetching = false;
      state.coinshistory = { count: 0, rows: [] };
      state.isError = true;
    },
  },
});

export const { actions: CoinsHistoryActions } = CoinsHistorySlice;

export default CoinsHistorySlice.reducer;
