import { Close } from "@mui/icons-material";
import { Dialog, DialogProps, MenuItem, Select } from "@mui/material";
import Button from "components/button";
import { BUTTON_SIZE } from "components/button/interface";
import TextField from "components/textfield";
import keys from "locale/keys";
import { isEqual } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SUPPORT_CATEGORIES,
  SUPPORT_DESCRIPTION_MAX_LEGTH,
  TICKET_TYPE,
} from "utils/constants";

import { SupportDialogProps } from "./interface";
import {
  DialogFieldWrapper,
  FieldTitle,
  ModalCloseButton,
  ModalContent,
  SubmitButtonWrapper,
} from "./styled.components";

const SupportDialog = (props: SupportDialogProps) => {
  const { onDismissDialog, showDialog, ticketDetails, onUpdateTicketStatus } =
    props;
  const { t } = useTranslation();
  const [category, setCategory] = useState(ticketDetails.category);

  const handleChange = (event: any) => {
    setCategory(event.target.value);
  };

  const buttonText = isEqual(ticketDetails.status, TICKET_TYPE.OPEN)
    ? t(keys.close)
    : t(keys.reopen);

  const handleClose: DialogProps["onClose"] = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    onDismissDialog();
  };

  const onChangeStatusButton = () => {
    if (isEqual(ticketDetails.status, TICKET_TYPE.RESOLVED)) {
      onUpdateTicketStatus(ticketDetails, TICKET_TYPE.OPEN);
    }
    onDismissDialog();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          maxHeight: "60%",
        },
      }}
      open={showDialog}
      onClose={handleClose}
    >
      <ModalContent data-testid="support-dialog-content">
        <ModalCloseButton onClick={onDismissDialog}>
          <Close />
        </ModalCloseButton>
        <DialogFieldWrapper>
          <FieldTitle>{t(keys.category)}</FieldTitle>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            defaultValue={category}
            onChange={(event: any) => handleChange(event)}
            sx={{
              "& .MuiSelect-select": {
                padding: "10px",
                fontSize: "14px",
              },
            }}
          >
            {SUPPORT_CATEGORIES.map((option: string, index: number) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </DialogFieldWrapper>
        <DialogFieldWrapper>
          <FieldTitle>{t(keys.description)}</FieldTitle>
          <TextField
            type="text"
            placeholder={t(keys.support_description)}
            onInput={(event: any) => {}}
            defaultValue={ticketDetails.description}
            multiline
            rows={4}
            inputProps={{
              style: {
                maxLength: SUPPORT_DESCRIPTION_MAX_LEGTH,
                fontSize: "14px",
              },
            }}
          />
        </DialogFieldWrapper>
        <SubmitButtonWrapper>
          <Button
            variant="contained"
            size={BUTTON_SIZE.MEDIUM}
            onClickHandler={onChangeStatusButton}
          >
            {buttonText}
          </Button>
        </SubmitButtonWrapper>
      </ModalContent>
    </Dialog>
  );
};

export default SupportDialog;
