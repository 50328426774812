import { Divider } from "@mui/material";
import { TableItemProps } from "commons/interface";
import ConfirmationDialog from "components/confirmationdialog";
import HeaderWithFilter from "components/headerwithfilter";
import CustomSnackbar from "components/snackbar";
import { Wrapper } from "containers/dashboard/styled.components";
import { State } from "data/reducers/state";
import { SupportTicketsAction } from "data/slices/support";
import {
  createSupportTicketApi,
  updateSupportTicketStatusApi,
} from "data/slices/support/api";
import {
  CreateSupportTicketResponse,
  SupportTicketsStateProps,
  UpdateSupportTicketResponse,
} from "data/slices/support/interface";
import keys from "locale/keys";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DASHBOARD_DEFAULT_ROUTE, SNACKBAR_TYPE } from "utils/constants";
import { getSupportTickets } from "utils/mappers/SupportListMapper";
import Support from "widgets/support";

const SupportContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({
    msg: "",
    type: SNACKBAR_TYPE.ERROR,
  });
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const { isFetching, isError, tickets } = useSelector<
    State,
    SupportTicketsStateProps
  >((state) => state.supporttickets);

  useEffect(() => {
    dispatch(SupportTicketsAction.request());
  }, [dispatch]);

  const onShowSnackbarHandler = (msg: string, type: number) => {
    setShowSnackbar(true);
    setSnackbarMsg({ msg, type });
  };

  const createSupportTicket = (category: string, description: string) => {
    createTicket(category, description);
  };

  const createTicket = async (category: string, description: string) => {
    setIsLoading(true);
    const response: CreateSupportTicketResponse = await createSupportTicketApi({
      category,
      description,
    });

    setIsLoading(false);
    if (isUndefined(response) || isUndefined(response.message)) {
      onShowSnackbarHandler(t(keys.generic_error), SNACKBAR_TYPE.ERROR);
    } else if (!isUndefined(response.error)) {
      onShowSnackbarHandler(response.message, SNACKBAR_TYPE.ERROR);
    } else {
      setShowSuccessDialog(true);
    }
  };

  const onUpdateTicketStatus = (
    selectedTicket: TableItemProps,
    updatedStatus: string,
  ) => {
    onUpdateSupportTicketStatus(selectedTicket, updatedStatus);
  };

  const onUpdateSupportTicketStatus = async (
    selectedTicket: TableItemProps,
    updatedStatus: string,
  ) => {
    setIsLoading(true);
    const response: UpdateSupportTicketResponse =
      await updateSupportTicketStatusApi({
        id: selectedTicket.id,
        category: selectedTicket.category,
        description: selectedTicket.description,
        status: updatedStatus,
      });

    setIsLoading(false);
    if (isUndefined(response) || isUndefined(response.message)) {
      onShowSnackbarHandler(t(keys.generic_error), SNACKBAR_TYPE.ERROR);
    } else if (!isUndefined(response.error)) {
      onShowSnackbarHandler(response.message, SNACKBAR_TYPE.ERROR);
    } else {
      onShowSnackbarHandler(response.message, SNACKBAR_TYPE.SUCCESS);
      dispatch(SupportTicketsAction.request());
    }
  };

  const dismissConfirmationDialog = () => {
    setShowSuccessDialog(false);
  };

  const goToSurveys = () => {
    navigate(DASHBOARD_DEFAULT_ROUTE, { replace: true });
  };

  const supportTickets = getSupportTickets(tickets);

  return (
    <>
      <HeaderWithFilter
        title={t(keys.support)}
        showFilter={false}
        showEdit={false}
      />
      <Divider />
      <Wrapper>
        <Support
          supportTickets={supportTickets}
          isError={isError}
          isLoadingTickets={isFetching}
          isLoading={isLoading}
          createSupportTicket={createSupportTicket}
          onUpdateTicketStatus={onUpdateTicketStatus}
        />
      </Wrapper>
      <CustomSnackbar
        msg={snackbarMsg.msg}
        type={snackbarMsg.type}
        show={showSnackbar}
        dismiss={() => setShowSnackbar(false)}
      />
      <ConfirmationDialog
        show={showSuccessDialog}
        dismiss={dismissConfirmationDialog}
        type={0}
        msg={t(keys.support_request_sent)}
        buttonText={t(keys.go_to_surveys)}
        hideCloseIcon={true}
        onButtonClickHandler={() => {
          goToSurveys();
        }}
      />
    </>
  );
};

export default SupportContainer;
