import { BaseStateProps } from "data/interface";

export interface CartCountResponse {
  message: string;
  response: {
    count: number;
  };
}

export interface CartCountStateProps extends BaseStateProps {
  cartcount: number;
  isError: boolean;
}

export const initialState: CartCountStateProps = {
  cartcount: 0,
  isError: false,
  isFetching: false,
};
