import { CouponProps } from "./interface";

export const getCoupons = (
  allCoupons: [string, CouponProps[]][],
  pageSize: number,
  pageNumber: number,
) => {
  const startIndex = pageNumber * pageSize;
  const endIndex = startIndex + pageSize;

  //this can be optimized?
  const filteredCoupons: Map<string, CouponProps[]> = new Map();
  const values = allCoupons.slice(startIndex, endIndex);
  values.forEach(([key, value]) => {
    filteredCoupons.set(key, value);
  });

  return filteredCoupons;
};
