import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./interface";

export const FlexiCardsSlice = createSlice({
  name: "flexicard",
  initialState: initialState,
  reducers: {
    request: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    success: (state, action) => {
      state.isFetching = false;
      state.isError = false;
      state.flexiCardDetails = action.payload;
    },
    error: (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.flexiCardDetails = { id: -1 };
    },
  },
});

export const { actions: FlexiCardsActions } = FlexiCardsSlice;

export default FlexiCardsSlice.reducer;
