import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const NotificationItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  margin-bottom: 1.25rem;
`;

export const DateLabel = styled(Typography)`
  font-weight: 400;
  font-size: 0.75rem;
`;

export const TitleLabel = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
`;

export const DescriptionLabel = styled(Typography)`
  font-weight: 400;
  font-size: 0.88rem;
`;
