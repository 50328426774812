import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import keys from "locale/keys";
import { useTranslation } from "react-i18next";

import AppLogo from "../../assets/icons/ApplogoBlack.svg";
import { AppLogoImg, Message, Wrapper } from "./styled.components";

const SurveySuccessRedirection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <AppLogoImg src={AppLogo} alt="app logo" />
      <CheckCircleOutlineOutlinedIcon
        style={{ color: "green", width: "4rem", height: "4rem" }}
      />
      <Message>{t(keys.survey_success_msg)}</Message>
    </Wrapper>
  );
};

export default SurveySuccessRedirection;
