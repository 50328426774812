import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";

import { GiftCardsRequest } from "./interface";

export const getGiftCardsApi = (request: GiftCardsRequest) => {
  //const url = `${ENDPOINTS.GIFTCARDS}?page_size=${request.pageSize}&page=${request.page}`;
  return axios.get(getBaseURL() + ENDPOINTS.GIFTCARDS, {
    headers: getHeaders(),
  });
};
