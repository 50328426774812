import { NotificationItemProps } from "widgets/dashboard/notifications/interface";

export const getNotifications = () => {
  const surveyNotifications: NotificationItemProps[] = [
    {
      date: "Dec 28, 2023",
      title: "Survey Invitations",
      description:
        "New Survey Available! Earn 50 points by completing this 10-minute survey on consumer habits.",
    },
    {
      date: "Dec 28, 2023",
      title: "Login Alert",
      description:
        "New Survey Available! Earn 50 points by completing this 10-minute survey on consumer habits.",
    },
  ];

  const systemNotifications: NotificationItemProps[] = [
    {
      date: "Dec 28, 2023",
      title: "Survey Invitations",
      description:
        "New Survey Available! Earn 50 points by completing this 10-minute survey on consumer habits.",
    },
  ];

  const transactionNotifications: NotificationItemProps[] = [];

  return {
    surveyNotifications,
    systemNotifications,
    transactionNotifications,
  };
};
