import { IconButton } from "@mui/material";

import ShoppingCart from "../../assets/icons/ShoppingCart.svg";
import { IconProps } from "./interface";
import { CountText, IconTextWrapper } from "./styled.components";

const IconWithCount = (props: IconProps) => {
  const { count = 0, onShowCartHandler } = props;

  return (
    <IconTextWrapper>
      <IconButton aria-label="cart" onClick={onShowCartHandler}>
        <img src={ShoppingCart} width="32px" height="32px" alt="cart" />
      </IconButton>
      {count !== 0 && <CountText>{count}</CountText>}
    </IconTextWrapper>
  );
};

export default IconWithCount;
