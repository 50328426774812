import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";

import { LoginRequestProps } from "./interface";

export const loginUserApi = async (payload: LoginRequestProps) => {
  try {
    const { data } = await axios.post(getBaseURL() + ENDPOINTS.LOGIN, payload);

    return data;
  } catch (e) {
    return e.response?.data;
  }
};
