import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";

import { SignupRequest } from "./interface";

export const signupApi = async (payload: SignupRequest) => {
  try {
    const { data } = await axios.post(getBaseURL() + ENDPOINTS.SIGNUP, payload);

    return data;
  } catch (e) {
    return e.response?.data;
  }
};

export const getInvitationDetailsFromHash = async (code: String) => {
  try {
    const url = getBaseURL() + ENDPOINTS.INVITATION_DETAILS + code;
    const { data } = await axios.post(url);

    return data;
  } catch (e) {
    return e.response?.data;
  }
};
