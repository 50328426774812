import { Button } from "@mui/material";
import { isEqual } from "lodash";
import { Fragment } from "react/jsx-runtime";

import { SurveyHeaderProps } from "./interface";
import {
  HeaderItem,
  StartWrapper,
  SurveyHeaderWrapper,
} from "./styled.components";

const SurveyHeader = (props: SurveyHeaderProps) => {
  const { headerItems, isCompletedSurveys } = props;

  return (
    <SurveyHeaderWrapper>
      {headerItems.map((item, index) => {
        const isButtonColumn =
          !isCompletedSurveys && isEqual(index, headerItems.length - 1);
        return (
          <Fragment key={index}>
            {isButtonColumn && (
              <StartWrapper>
                <Button></Button>
              </StartWrapper>
            )}
            {!isButtonColumn && (
              <HeaderItem index={index} isbuttoncolumn={isButtonColumn}>
                {item}
              </HeaderItem>
            )}
          </Fragment>
        );
      })}
    </SurveyHeaderWrapper>
  );
};

export default SurveyHeader;
