import { Close } from "@mui/icons-material";
import { Dialog, DialogProps, Stack } from "@mui/material";
import Button from "components/button";
import { BUTTON_SIZE } from "components/button/interface";

import SuccessTick from "../../assets/icons/SuccessTick.svg";
import WarningTick from "../../assets/icons/WarningTick.svg";
import { ConfirmationDialogProps } from "./interface";
import {
  ButtonWrapper,
  DialogCloseButton,
  DialogContentWrapper,
  MessageText,
} from "./styled.components";

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    show,
    dismiss,
    buttonText,
    msg,
    onButtonClickHandler,
    type,
    hideCloseIcon = false,
  } = props;

  const handleClose: DialogProps["onClose"] = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    dismiss();
  };

  const icon = type === 0 ? SuccessTick : WarningTick;
  return (
    <Dialog maxWidth="sm" open={show} onClose={handleClose}>
      <DialogContentWrapper>
        {!hideCloseIcon && (
          <DialogCloseButton onClick={dismiss}>
            <Close />
          </DialogCloseButton>
        )}
        <Stack direction="row" gap="16px" alignItems="flex-start">
          <img src={icon} alt="success" style={{ paddingTop: "4px" }} />
          <MessageText>{msg}</MessageText>
        </Stack>
        <ButtonWrapper>
          <Button
            onClickHandler={onButtonClickHandler}
            size={BUTTON_SIZE.MEDIUM}
          >
            {buttonText}
          </Button>
        </ButtonWrapper>
      </DialogContentWrapper>
    </Dialog>
  );
};

export default ConfirmationDialog;
